import axios from '../axios'

//获取参数类型列表 
export const getParamType = () => {
    return axios({
      method: "get",
      url: `parameter/types`,
    })
  }

  
  //参数列表分页
export const getParamPage = (data) => {
    return axios({
      method: "get",
      url: `parameter/page?size=${data.size}&current=${data.current}&originalId=${data.originalId}`,
    })
  }

  //参数布尔列表不分页
export const getParamBoolList = (data) => {
    return axios({
      method: "get",
      url: `parameter/${data}/boolParams`,
    })
  }
  //参数数值列表不分页
export const getParamStrList = (data) => {
    return axios({
      method: "get",
      url: `parameter/${data}/notBoolParams`,
    })
  }
  //参数非布尔参数列表不分页
  export const getParamList = (data) => {
    return axios({
      method: "get",
      url: `parameter/${data}/planParams`,
    })
  }
  //获取网管下参数列表不分页
  export const getGatewayParams = (data) => {
    return axios({
      method: "get",
      url: `parameter/${data}/gatewayParams`,
    })
  }

  // 参数详情
  export const paramDetail = (data) => {
    return axios({
      method: "get",
      url: `parameter/${data}`,
    })
  }
  
  // 新建公共参数
  export const createPublicParam = (data) => {
    return axios({
      method: "post",
      url: `parameter/public`,
      data: data
    })
  }
  
  // 新建私有参数
  export const createParam = (data) => {
    return axios({
      method: "post",
      url: `parameter`,
      data: data
    })
  }
  
  // 编辑公共参数
  export const editPublicParam = (data) => {
    return axios({
      method: "put",
      url: `parameter/public`,
      data: data
    })
  }
  
  // 编辑私有参数
  export const editParam = (data) => {
    return axios({
      method: "put",
      url: `parameter`,
      data: data
    })
  }
  
  // 删除公共参数
  export const delPublicParam = (data) => {
    return axios({
      method: "delete",
      url: `parameter/public/${data}`,
    })
  }
  
  // 删除私有参数
  export const delParam = (data) => {
    return axios({
      method: "delete",
      url: `parameter/${data}`,
    })
  }

  //导入参数
export const putParameter = (data) => {
    return axios({
      method: "post",
      url: `/parameter/import`,
      data:data
    })
  }
  //导出参数
  export const getParameter = (data) => {
    return axios({
      method: "get",
      url: `/parameter/${data}/export`,
    })
  }
  //创建触发器时候参数的下拉选择  设备ID
export const getParamArr = data => {
    return axios({
      method: "get",
      url: `parameter/subList?subOriginalId=${data}`,
    })
  }