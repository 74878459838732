import axios from '../axios'

//图片类型新增
export const postConfigurationImgType = data => {
  return axios({
    method: "post",
    url: `/configurationImgType`,
    data:data,
  })
}
//图片类型新增
export const putConfigurationImgType = data => {
  return axios({
    method: "put",
    url: `/configurationImgType`,
    data:data,
  })
}
//图片类型详情
export const getConfigurationImgType = data => {
  return axios({
    method: "get",
    url: `/configurationImgType/${data}`,
 
  })
}
//图片类型删除
export const delConfigurationImgType = data => {
  return axios({
    method: "delete",
    url: `/configurationImgType/${data}`,
  
  })
}
//图片类型列表
export const getConfigurationImgTypeList = data => {
  return axios({
    method: "get",
    url: `/configurationImgType/list`,
    data:data,
  })
}
//图片类型列表分页
export const getConfigurationImgTypePage = data => {
  return axios({
    method: "get",
    url: `/configurationImgType/page?current=${data.current}&size=${data.size}`,
    
  })
}
//组态添加图片
export const postImg = data => {
  return axios({
    method: "post",
    url: `/configurationImg`,
    data:data,
  })
}