import axios from '../axios'

//查看角色列表
export const roleList = data => {
  return axios({
    method: "get",
    url: `role/page?current=${data.current}&size=${data.size}&roleName=${data.roleName}`,
  })
}

//创建角色
export const createRole = data => {
  return axios({
    method: "post",
    url: `role`,
    data: data,
    
  })
}

//编辑角色
export const editRole = data => {
  return axios({
    method: "put",
    url: `role`,
    data: data,
  })
}

//删除角色
export const delRole = data => {
  return axios({
    method: "delete",
    url: `role/${data}`,
  })
}

//分配权限
export const distributionPermission = data => {
  return axios({
    method: "put",
    url: `role/permission`,
    data: data,
  })
}

//权限信息获取
export const roleInfo = data => {
  return axios({
    method: "get",
    url: `role/${data}`,
  })
}

//获取单个权限配置
export const getSingleSettinginfo = data => {
  return axios({
    method: "get",
    url: `role/${data}/permissions`,
  })
}