// HMIWebSocket Web Socket js
//
//  依赖
//      无
//
//  历史
//      创建    LEE     2021/06/15
//=============================================================================
//-----------------------------------------------------------------------------
// Web Socket
//
import gInterface from "./HMIInterface";
import { baseUrl } from '../global.js';
import $store from '../../store/store'
let resolveFun = null
class HMIWebSocket {
  //---------------------------------------------------------------------------
  // 构造函数
  // 
  constructor() {
    // Web Socket handle
    this.handle         = null;
    // Web Socket url
    this.url            = '';
    // 接收到的消息
    this.data           = '';
    // 打开Socket的句柄
    this.openHandle     = null;
    // 接收到的消息的句柄
    this.recevHandle    = null;

    this.timeoutObj=null;
    this.serverTimeoutObj=null;
  }
  //---------------------------------------------------------------------------
  // make url
  //
  makeUrl(vToken, vTokenId, pId) {
    // url
    // let lUrl = `wss://ssl.cniot.fun/socket/data/${vToken}/${vKey}`;
    //新环境的websocket的测试URL
    //吴迪  2021-06-25
    let lUrl = `wss://${baseUrl.split("https://")[1]}socket/data/${pId}/${vTokenId}`

    // 返回Url
    return lUrl;
  }
  //---------------------------------------------------------------------------
  // 打开与接收句柄
  //
  setOpenHandle(vOpenHandle) {
    // 打开Socket的句柄
    this.openHandle             = vOpenHandle;
  }
  //---------------------------------------------------------------------------
  // 设置接收消息的函数句柄
  //
  setRecevMsgHandle(vRecevHandle) {
    // 接收到的消息的句柄
    this.recevHandle            = vRecevHandle;
  }
  //---------------------------------------------------------------------------
  // 打开Web Socket
  //
  openWebSocket(url, resolve) {
    resolveFun = resolve
    // Web Socket url
    if(url != this.url) {
      // 关闭
      // this.closeWebSocket();
      
      // Web Socket url
      this.url                  = url;
      // 创建Handle
      this.handle               = new WebSocket(url);

      // 注册事件
      this.handle.onerror       = this.errorEvent;
      this.handle.onmessage     = this.messageEvent;
      this.handle.onopen        = this.openEvent;
      this.handle.onclose       = this.closeEvent;
      // 打开Socket的句柄
      this.handle.openHandle    = this.openHandle;
      // 接收到的消息的句柄
      this.handle.recevHandle = this.recevHandle;
      this.start();
    }
  }
  //---------------------------------------------------------------------------
  // 关闭Web Socket
  //
  closeWebSocket() {
    clearInterval(this.timeoutObj)
    // Web Socket url
    this.url = '';
    // 检测是否存在
    if(this.handle) {
      // 关闭
      this.handle.close();
    }else{
       this.closeEvent();
    }
  }
  //---------------------------------------------------------------------------
  // 错误情况
  //
  errorEvent(e) {
    // 错误情况
    console.log(e)
  }
  //---------------------------------------------------------------------------
  // 打开Socket事件
  //
  openEvent() {
    // 打开Socket的句柄
    if(this.openHandle) {
      // 执行打开句柄
      this.openHandle();
      setTimeout(() => {
        gInterface.openWs(resolveFun)
      }, 1000);
      

    }
  }
  //---------------------------------------------------------------------------
  // 关闭Socket事件
  //
  closeEvent() {
    // 关闭
    gInterface.closeWs()
    // this.timeoutObj=null;
  }
  //---------------------------------------------------------------------------
  // 发送消息事件
  //
  sendEvent(msg) {
    // 检查是否有效
    if(this.handle) {
      // 发送消息事件
      // this.handle.send(msg);
    }
  }
  //---------------------------------------------------------------------------
  // 接收到消息事件
  //
  messageEvent(event) {
    // 接收到消息事件
    if(event.data) {
      // 接收到的消息的句柄
      if (this.recevHandle) {
        // 接收到消息
        this.recevHandle(event.data);
        resolveFun(true)
      }
    }
  }

start() {
      this.timeoutObj&&clearTimeout(this.timeoutObj);
      this.serverTimeoutObj&&clearTimeout(this.serverTimeoutObj);
      // console.log(this.handle);
      let that=this
     
      // this.timeoutObj=setInterval(function () {
         // 检查是否有效
    // if(that.handle) {
    //   // 发送消息事件
    //   that.handle.send("ping%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%");
    //   console.log("发送消息");
    // }
    //   },600000);

    }
 

}
 
//=============================================================================
// 常量 Web Socket
//
const cHMIWebSocket = new HMIWebSocket;
//=============================================================================
// 监听窗口关闭事件。
//  当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口。
//
window.onbeforeunload = function () {
  // 关闭Web Socket
  if ($store.state.projectId) {
    cHMIWebSocket.closeWebSocket();
  }
}

// 外部接口暴露
export default cHMIWebSocket;