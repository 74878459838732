
const directive = {
  inserted(el, binding) {
    const rate = binding?.value ??   100;
    if (!el) return;
    const scroll = () => {
      let parentDom = el;
      // 判断是否有滚动条
      if(parentDom.scrollHeight <= parentDom.clientHeight) return
      //判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
      if (
        Math.ceil(parentDom.scrollTop)+parentDom.clientHeight ===
        parentDom.scrollHeight
      ) {
        parentDom.scrollTop = 0;
        window.pageYOffset=0
      } else {
        // let top=  window.pageYOffset++ ||  parentDom.scrollTop++;
        let top=  window.pageYOffset++ 
        // 元素自增距离顶部
        parentDom.scrollTop= Math.floor(top*1) 
      }
    };
   
    let timer = setInterval(scroll, rate);
    el.onmouseenter = () => {
      clearInterval(timer);
      timer = null;
    };
    el.onmouseleave = function () {
      timer = setInterval(scroll, 100);
    };
  },
};
export default directive