import VueRouter from 'vue-router';
import api from '../http/api';
import $store from '../store/store';
import { addDynamicMenuRoutes} from '../util/guardTool';
// import { addDynamicMenuRoutes, getDefaultMenuActive} from '../util/guardTool';
// import { addDynamicMenuRoutes, getDefaultMenuActive, getGuard } from '../util/guardTool';
import { routes } from '../util/routes';


let router = new VueRouter({
    // mode: 'history',
    routes: routes
})

function ruleApi() {
    let _async = new Promise(function (resolve, reject) {
        api.pub.ruleApi().then(response => {
            if ($store.state.token.length > 0 && response.code == 200) {
                resolve(response.data)
            } else {
                reject('')
            }
        })
    })
    return _async
}

//导航守卫
router.beforeEach((to, from, next) => {
    //from 从哪来  to 到哪去 next() 回调函数
    if ($store.state.token.length > 0) {
        if (from.name === 'login') {
            ruleApi().then((res) => {
                $store.commit('setRoutes', addDynamicMenuRoutes(res));
                // $store.commit('setActiveMenu', getDefaultMenuActive(res));
                $store.commit('setTreeList', res);

                return
            })
        } 
        // if (to.name === 'login') {
        //     next('/functionCenter');
        //     return;
        // }
        // ruleApi().then((res) => {
        //     $store.commit('setRoutes', addDynamicMenuRoutes(res));
        //     // $store.commit('setActiveMenu', getDefaultMenuActive(res));
        //     $store.commit('setTreeList', res);

        //     if (to.name === 'login') {
        //         next('/home');
        //         return;
        //     }

        //     return
        // })
    }
    next()
})

export default (router);