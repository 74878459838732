/**
 * 全局常量、方法封装模块
 * 通过原型挂载到Vue属性
 * 通过 this.Global 调用
 */

 //本地测试
// const local = 'https://system.cniot.vip/';
// const localAddress = 'http://localhost:8080/';
// const localPic = 'https://oss.cniot.fun/iot-system/test/image/';
// const localImg = 'https://oss.cniot.fun/iot-system/test/capture/' 
// const localWork = 'https://oss.cniot.fun/iot-system/test/file/workorder/' 
// const localvisit = 'http://localhost:8080/'

//线上测试
// const development = 'https://system.cniot.vip/';
// const developmentAddress = 'https://sysweb.cniot.vip/';
// const developmentPic = 'https://oss.cniot.fun/iot-system/test/image/'
// const developmentImg = 'https://oss.cniot.fun/iot-system/test/capture/'
// const developmentWork = 'https://oss.cniot.fun/iot-system/test/file/workorder/' 
// const developmentvisit = 'https://sysweb.cniot.vip/'
// const wechart = 'https://system.cniot.vip/index.html#/'

//  // 本地正式
const local = 'https://iotsystem.cniot.vip/';
const localAddress = 'http://localhost:8080/';
const localPic = 'https://oss.cniot.fun/iot-system/prod/image/';
const localImg = 'https://oss.cniot.fun/iot-system/prod/capture/'
const localWork = 'https://oss.cniot.fun/iot-system/prod/file/workorder/' 
const localvisit = 'https://www.cniot.vip/'

//  //线上正式
const development = 'https://iotsystem.cniot.vip/';
const developmentAddress = 'https://www.cniot.vip/';
const developmentPic = 'https://oss.cniot.fun/iot-system/prod/image/';
const developmentImg = 'https://oss.cniot.fun/iot-system/prod/capture/'
const developmentWork = 'https://oss.cniot.fun/iot-system/prod/file/workorder/' 
const developmentvisit = 'https://www.cniot.vip/'
const wechart = "https://iotsystem.cniot.vip/index.html#/"

export const baseUrl = process.env.NODE_ENV === 'development' ? local :  development ;
export const addressUrl = process.env.NODE_ENV === 'development' ? localAddress :  developmentAddress;
export const pic = process.env.NODE_ENV === 'development' ? localPic :  developmentPic;
export const imgs= process.env.NODE_ENV === 'development' ? localImg :  developmentImg;
export const workUrl= process.env.NODE_ENV === 'development' ? localWork :  developmentWork;
export const visitUrl = process.env.NODE_ENV === 'development' ? localvisit :  developmentvisit;
export const payUrl = wechart;
export default {
    baseUrl,
    addressUrl,
    pic,
    imgs,
    visitUrl,
    workUrl,
}