import axios from '../axios'

//联网方式分页
export const networkModeList = data => {
  return axios({
    method: "get",
    url: `/networkMode/page?size=${data.size}&current=${data.current}&networkMode=${data.networkMode}`,
  })
}

//联网方式新增
export const addNetworkMode = data => {
  return axios({
    method: "post",
    url: `/networkMode`,
    data:data
  })
}

//联网方式编辑
export const editNetworkMode = data => {
  return axios({
    method: "put",
    url: `/networkMode`,
    data:data
  })
}

//联网方式删除
export const delNetworkMode = data => {
  return axios({
    method: "delete",
    url: `/networkMode/${data}`,
  })
}

//联网方式详情
export const networkModeDetail = data => {
  return axios({
    method: "get",
    url: `/networkMode/${data}`,
  })
}