import axios from '../axios'

//新增工单
export const addWorkOrder = (data) => {
    return axios({
      method: "post",
      url: `lzWorkOrder`,
      data:data,
    })
  }
//修改工单
export const editWorkOrder = (data) => {
    return axios({
      method: "put",
      url: `lzWorkOrder`,
      data:data,
    })
  }
//删除工单
export const delWorkOrder = (data) => {
    return axios({
      method: "delete",
      url: `lzWorkOrder/${data}`,
    })
  }
//查询工单
export const getWorkOrder = (data) => {
    return axios({
      method: "get",
      url: `lzWorkOrder/order/${data}`,
    })
  }
//获取工单子集
export const getWorkOrderList= (data) => {
    return axios({
      method: "get",
      url: `lzWorkOrder/orderList/${data}`,

    })
  }
//获取单平台三级联动所有
export const getWorkorderLists = (data) => {
    return axios({
      method: "get",
      url: `lzWorkOrder/orderLists/${data}`,
    })
  }
//查询所有三级联动
export const getALLWorkOrderLists = (data) => {
    return axios({
      method: "get",
      url: `lzWorkOrder/orderLists`,
      data:data,
    })
  }
//修改工单目录排序
export const ediWorkTorderBy = (data) => {
    return axios({
      method: "put",
      url: `lzWorkOrder/orderBy`,
      data:data,
    })
  }
//上传说明文档pdf
export const fileWorkOrder = (data,onUploadProgress) => {
    return axios({
      method: "post",
      url: `/lzWorkOrder/upFile`,
      data:data,
      onUploadProgress:onUploadProgress,
    })
  }