import axios from '../axios';

//查sim卡列表1
export const getIccidList = data => {
    return axios({
        method: "get",
        url: `dtuIccid/iccidList?userId=${data.userId}&size=${data.size}&current=${data.current}&sendStatus=${data.sendStatus}&projectName=${data.projectName}`,
    })
}
// export const getIccidList = data => {
//     return axios({
//         method: "get",
//         url: `dtuIccid/iccidDevPage?userId=${data.userId}&size=${data.size}&current=${data.current}`,
//     })
// }

//查sim详情1
export const getIccidDetail = data => {
    return axios({
        method: "get",
        url: `dtuIccid/iccidDev/${data}`,
    })
}

//重定向1
export const re = data => {
    return axios({
        method: "get",
        url: `wechat/payIccidOauth/${data.iccid}/${data.dtuId}`,
    })
}

//openid
export const getOpenId = data  => {
    return axios({
        method: "get",
        url: `wechat/paycode/${data}`,
    })
}

//套餐列表
export const packageList = data  => {
    return axios({
        method: "get",
        url: `dtuIccid/GprsPackage/${data.iccid}`,
    })
}
//检验接口
export const checklccid = data  => {
    return axios({
        method: "get",
        url: `/dtuIccid/checkIccid?ccid=${data}
        `,
    })
}
//套餐列表
export const payfor = data => {
    return axios({
        method: "post",
        url: `wechatPay/iccidJsapiPay`,
        data: data
    })
}

//Native支付下单
export const payIccidNativePay = data => {
    return axios({
      method: "post",
      url: `/wechatPay/nativePay`,
      data:data,
    })
  }
//支付列表
export const listApi = data => {
    return axios({
        method: "get",
        url: `wechatPay/payLogPage?userId=${data.userId}&size=${data.size}&current=${data.current}`,
    })
}
//获取用户移动卡充值订单
export const getPayLogPage = data  => {
    return axios({
        method: "get",
        url: `/payIccidLog/payLogPage?size=${data.size}&current=${data.current}&userId=${data.userId}`,
    })
  }
  //获取用户移动卡充值订单详情
  export const getPayTopLog = data  => {
    return axios({
        method: "get",
        url: `/payIccidLog/payTopLog/${data}`,
    })
  }
//删除订单
export const delOrder = data => {
    return axios({
        method: 'delete',
        url:`/payIccidLog/payLog/${data}`
    })
}
//支付状态
export const payStatus = data => {
    return axios({
      method: 'get',
      url:`/payIccidLog/payIccidTop/${data}`
    })
  }
