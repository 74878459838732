// HMIEvents 事件处理js
//
//  依赖
//
//  历史
//      创建    LEE     2020/12/25
//=============================================================================
import HG from './HMIGlobal.js'
import HP from './HMIProject.js'
import gDraw from './HMIDraw.js'
import gZoomHandle from './HMIZoombar.js'
import HMIOperateCtrlMG from './HMIOperateCtrlMG.js'
import gWebSocket from "./HMIWebSocket.js";
//-----------------------------------------------------------------------------
// 绘制画布的鼠标事件 按键类型
//
const VirtualKeyType = {
    // 回车
    VK_ENTER:   13,

    // left
    VK_LEFT:    37,
    // up
    VK_UP:      38,
    // right
    VK_RIGHT:   39,
    // down
    VK_DOWN:    40,
    // delete
    VK_DELETE:  46,

    // C
    VK_C:       67,
    // V
    VK_V:       86,
    // X
    VK_X:       88,
};
//-----------------------------------------------------------------------------
// 绘制画布的鼠标事件 按键事件
//
const cHMIEvents = {
    //-----------------------------------------------------------------------------
    // 绘制画布的事件 动作处理
    //
    ctrlsChanged: false,
    modifyUndoOperate: false,
    //-----------------------------------------------------------------------------
    // 绘制画布的事件 初始化
    //
    regEvent: function(editMode=true) {
        // 注册窗口鼠标事件
        window.addEventListener('mousedown',        this.mouseDownEvent,    false);
        window.addEventListener('mouseup',          this.mouseUpEvent,      false);
        window.addEventListener('mousemove',        this.mouseMoveEvent,    false);
        window.addEventListener('wheel',            this.mouseWheelEvent,   false);
        // 注册窗口按键事件
        window.addEventListener('keydown',          this.keyDownEvent,      false);
        window.addEventListener('keyup',            this.keyUpEvent,        false);
        // 注册窗口改变事件
        window.addEventListener('resize',           this.wndResize,         false);

        // 编辑模式下注册窗口关闭事件
        if(editMode) {
            // 注册窗口关闭前检查事件
            window.addEventListener('beforeunload', this.wndBeforeunload,   false);
        } else {
            // 删除窗口关闭前检查事件
            window.removeEventListener('beforeunload', this.wndBeforeunload,false);
        }
        // 编辑模式下本地储存数据
        if(editMode) {
            // 本地储存改变
            window.addEventListener('storage', function(e) {                
                // 检查键值
                if(HG.$Set.allSettings.ControlsCopyCnt === e.key) {
                    // 转换数量
                    HG.$Set.allCopyData.count = parseInt(e.newValue);
                    // 清除复制偏移值
                    HP.vHMIPrjFunc.clearCopyOffside();
                }
                // 检查键值
                if(HG.$Set.allSettings.ControlsCopyDat === e.key) {
                    // 转换数据
                    HG.$Set.allCopyData.ctrls = JSON.parse(e.newValue);
                }
            }, false);
        }
        // 是否为编辑模式
        gDraw.editingMode = editMode;
    },
    //-----------------------------------------------------------------------------
    // 检查键盘、鼠标事件的源对象 
    //
    checkEventSourceValid: function (event, canvasEvent, operateCtrl=false) {
        // 检查是否存在
        var vSourceCtrl     = null, vParentCtrl = null;
        var vOperateCtrlTag = '';
        // 获取鼠标操作源对象
        if (event.target) {
            vSourceCtrl = event.target;
        }
        else if (event.srcElement) {
            vSourceCtrl = event.srcElement;
        }

        // 上次操作的控件的tag名
        vOperateCtrlTag = vSourceCtrl.tagName.toLowerCase();
        // 检查是否操作控件
        if(operateCtrl) {
            // 上次操作的控件的tag名
            gEventsGlobalAttributes.lastOperateCtrlTag = vOperateCtrlTag;
        }

        // 检查tag名称
        if(vOperateCtrlTag === 'canvas') {
            // 检查ID是否存在
            if(canvasEvent.id) {
                // 获取canvas的ID
                if(canvasEvent.id == vSourceCtrl.id) {
                    return true;
                } else {
                    // 检查是否为缩放工具栏
                    if(vSourceCtrl.id === HG.$Set.allCanvasID.zoomBar) {
                        // 获取设置id
                        canvasEvent.id = vSourceCtrl.id;
                        return true;
                    }
                }
            }
        }
        // 添加外部控件属性
        if(vSourceCtrl.isOutCtrl) {
            // 有控件覆盖在canvas上面时, 底层的控件是否为canvas
            vParentCtrl = document.getElementById(canvasEvent.id);
            // 检查位置是否在其中
            if(vParentCtrl) {
                // 获取clientX, Y
                let vClientX    = event.clientX /gDraw.domZoom;
                let vClientY    = event.clientY /gDraw.domZoom;
                let vPRect      = vParentCtrl.getBoundingClientRect();

                // 检查是否兼容getBoundingClientRect()
                if((!vPRect.x) && (!vPRect.y)) {
                    // 位置
                    vPRect.x   = vParentCtrl.getBoundingClientRect().left;
                    vPRect.y   = vParentCtrl.getBoundingClientRect().top;
                }

                // 检查是否在其中
                if ((vClientX >= vPRect.x) && (vClientX < (vPRect.x + vPRect.width)) &&
                    (vClientY >= vPRect.y) && (vClientY < (vPRect.y + vPRect.height))) {
                    // 也认为在其中
                    return true;
                }
            }
        }
            
        return false;
    },
    //-----------------------------------------------------------------------------
    // 绘制画布的鼠标事件 左键按下
    //
    mouseDownEvent: function (event) {
        // 获取clientX, Y
        let vClientX = event.clientX /gDraw.domZoom;
        let vClientY = event.clientY /gDraw.domZoom;
        let vOffsetX = 0;
        let vOffsetY = 0;
        // canvas event
        let vCanvasEvent = { id: HG.$Set.allCanvasID.main };

        // 检查键盘、鼠标事件的源对象 
        if (!cHMIEvents.checkEventSourceValid(event, vCanvasEvent, true)) {
            // 无效操作控件
            return;
        }

        // 检查是否有效
        if (vClientX == 'undefined' || isNaN(vClientX)) {
            vClientX = window.event.clientX /gDraw.domZoom;
        }
        if (vClientY == 'undefined' || isNaN(vClientY)) {
            vClientY = window.event.clientY /gDraw.domZoom;
        }
        
        // scroll size
        vOffsetX = (window.pageXOffset !== undefined) ? window.pageXOffset : (document.documentElement || document.body.parentNode || document.body).scrollLeft;
        vOffsetY = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
        
        // 检查是否为主canvas
        if(vCanvasEvent.id == HG.$Set.allCanvasID.main) {
            // 计算偏移量
            vOffsetX = gDraw.rectCanvasArea.l - vOffsetX;
            vOffsetY = gDraw.rectCanvasArea.t - vOffsetY;
            
            // 检查是否为编辑模式
            if(gDraw.editingMode) {
                // 选中点
                gDraw.selectedPointSt.x = (vClientX - vOffsetX) * gDraw.getRealSizeRate();
                gDraw.selectedPointSt.y = (vClientY - vOffsetY) * gDraw.getRealSizeRate();
                // 位置计算
                gDraw.selectedPointSt.x += gDraw.settings.scrollPosLeftRate   * gDraw.settings.width;
                gDraw.selectedPointSt.y += gDraw.settings.scrollPosTopRate    * gDraw.settings.height;

                // gDraw.selectedPointSt.x = gDraw.selectedPointSt.x ;
                // gDraw.selectedPointSt.y = gDraw.selectedPointSt.y ;
                // 检查选中点
                if ((gDraw.selectedPointSt.x >= 0) && (gDraw.selectedPointSt.y >= 0)) {
                    // 检查是否超出显示区域
                    if ((gDraw.selectedPointSt.x >= gDraw.settings.width) || 
                        (gDraw.selectedPointSt.y >= gDraw.settings.height)) {
                        // 超出画面区域
                        return;
                    }
                    // 选中点获取
                    gDraw.selectedPointEd.x = gDraw.selectedPointSt.x;
                    gDraw.selectedPointEd.y = gDraw.selectedPointSt.y;
                    // 设置选中
                    gDraw.selected = true;

                    // shift 按键按下
                    if (window.event.shiftKey) {
                        // 设置为未选中
                        gDraw.ctrlselected.selected = false;
                        gDraw.ctrlselected.status = 0;
                        // 鼠标样式设置
                        gDraw.setCanvasCursor();
                    } else {
                        // 动作处理
                        this.ctrlsChanged       = false;
                        // 选中控件的属性改变
                        this.modifyUndoOperate  = HP.vHMIPrjFunc.selectedCtrlsModifyAttributes();
                    }

                    // 控件选中时移动的偏移
                    gDraw.ctrlselectedMove.x = gDraw.selectedPointSt.x;
                    gDraw.ctrlselectedMove.y = gDraw.selectedPointSt.y;
                }
            } else {
                // 选中点
                gDraw.selectedPointSt.x = (vClientX - vOffsetX) * gDraw.getRealSizeRate();
                gDraw.selectedPointSt.y = (vClientY - vOffsetY) * gDraw.getRealSizeRate();
                // 位置计算
                gDraw.selectedPointSt.x += gDraw.settings.scrollPosLeftRate   * gDraw.settings.width;
                gDraw.selectedPointSt.y += gDraw.settings.scrollPosTopRate    * gDraw.settings.height;
               
               
                // gDraw.selectedPointSt.x = gDraw.selectedPointSt.x /gDraw.domZoom;
                // gDraw.selectedPointSt.y = gDraw.selectedPointSt.y /gDraw.domZoom;
                // 检查选中点
                if ((gDraw.selectedPointSt.x >= 0) && (gDraw.selectedPointSt.y >= 0)) {
                    // 检查是否超出显示区域
                    if ((gDraw.selectedPointSt.x >= gDraw.settings.width) || 
                        (gDraw.selectedPointSt.y >= gDraw.settings.height)) {
                        // 超出画面区域
                        return;
                    }
                    // 检查是否在可执行的控件
                    if(gDraw.onExecableCtrl) {
                        // 鼠标样式设置
                        gDraw.setCanvasCursor(true);
                    }
                }
            }
            // 活动状态
            gDraw.setEventActive();
        }
        // 检查是否为缩放区域canvas
        else if(vCanvasEvent.id == HG.$Set.allCanvasID.zoomBar) {
            // 计算偏移量
            vClientX = (vClientX - vOffsetX) * gDraw.getRealSizeRate();
            vClientY = (vClientY - vOffsetY) * gDraw.getRealSizeRate();
            // 选中点获取
            gZoomHandle.clickZoomArea(vClientX, vClientY);
        }
    },
    //-----------------------------------------------------------------------------
    // 绘制画布的鼠标事件 左键释放
    //
    mouseUpEvent: function (event) {
        // canvas event
        let vCanvasEvent = { id: HG.$Set.allCanvasID.main };
        // 检查键盘、鼠标事件的源对象 
        if (!cHMIEvents.checkEventSourceValid(event, vCanvasEvent, true)) {
            // 无效操作控件
            return;
        }
        // 检查是否为主canvas
        if(vCanvasEvent.id == HG.$Set.allCanvasID.main) {
            // 是否为编辑模式
            if(gDraw.editingMode == true) {
                // 检查是否选中
                if (gDraw.selected) {
                    // 检查是否点击在控件上
                    if (!gDraw.ctrlselected.selected) {
                        // 获取选中区域
                        gDraw.selectedRect.l = Math.round(Math.min(gDraw.selectedPointSt.x, gDraw.selectedPointEd.x));
                        gDraw.selectedRect.t = Math.round(Math.min(gDraw.selectedPointSt.y, gDraw.selectedPointEd.y));
                        gDraw.selectedRect.w = Math.round(Math.max(gDraw.selectedPointSt.x, gDraw.selectedPointEd.x) - gDraw.selectedRect.l);
                        gDraw.selectedRect.h = Math.round(Math.max(gDraw.selectedPointSt.y, gDraw.selectedPointEd.y) - gDraw.selectedRect.t);

                        // 检查尺寸
                        if ((gDraw.selectedRect.w < HG.$Set.allSettings.selectedFrameSetting.halfSize) &&
                            (gDraw.selectedRect.h < HG.$Set.allSettings.selectedFrameSetting.halfSize)) {
                            // 点击选中控件
                            HP.vHMIPrjFunc.pointCtrl(gDraw.selectedRect.l, 
                                                     gDraw.selectedRect.t, 
                                                     window.event.shiftKey);
                        }
                        else {
                            // 检查控件是否选中
                            HP.vHMIPrjFunc.selectCtrl(gDraw.selectedRect.l,
                                                      gDraw.selectedRect.t,
                                                      gDraw.selectedRect.l + gDraw.selectedRect.w,
                                                      gDraw.selectedRect.t + gDraw.selectedRect.h,
                                                      window.event.shiftKey);
                        }
                    } else {
                        // 获取选中区域
                        gDraw.selectedRect.l = Math.round(Math.min(gDraw.selectedPointSt.x, gDraw.selectedPointEd.x));
                        gDraw.selectedRect.t = Math.round(Math.min(gDraw.selectedPointSt.y, gDraw.selectedPointEd.y));
                        gDraw.selectedRect.w = Math.round(Math.max(gDraw.selectedPointSt.x, gDraw.selectedPointEd.x) - gDraw.selectedRect.l);
                        gDraw.selectedRect.h = Math.round(Math.max(gDraw.selectedPointSt.y, gDraw.selectedPointEd.y) - gDraw.selectedRect.t);

                        // 检查尺寸
                        if ((gDraw.selectedRect.w < HG.$Set.allSettings.selectedFrameSetting.halfSize) &&
                            (gDraw.selectedRect.h < HG.$Set.allSettings.selectedFrameSetting.halfSize)) {
                            // 检查Control按键是否按下
                            if(window.event.ctrlKey) {
                                // 将选中控件设置为主选择控件
                                // 设置[mainSel 属性表示多选控件中的主控件，对齐参考控件]
                                HP.vHMIPrjFunc.setMainSelCtrl(gDraw.selectedRect.l, gDraw.selectedRect.t);
                            }
                        }
                    }
                }
                // 动作处理
                if(this.modifyUndoOperate) {
                    // 控件改变
                    if(!this.ctrlsChanged) {
                        // 取消属性修改撤销
                        HMIOperateCtrlMG.cancelLastUndo(HP.vHMIPrj.curPg);
                    } else {
                        // 刷新工具栏状态
                        HG.$Set.projectStatus.toolbarStatus = true;
                    }
                }
            }
            else {
                // 选中点获取
                if ((Math.abs(gDraw.selectedPointEd.x - gDraw.selectedPointSt.x) < 10) &&
                    (Math.abs(gDraw.selectedPointEd.y - gDraw.selectedPointSt.y) < 10)) {
                        // 输出点击位置
                        HP.vHMIPrjFunc.execPointOperation(gDraw.selectedPointEd.x, gDraw.selectedPointEd.y);
                        // 检查是否在可执行的控件
                        if(gDraw.onExecableCtrl) {
                            // 鼠标样式设置
                            gDraw.setCanvasCursor();
                        }
                    } else {
                        // 输出点击位置
                        console.log('点击操作无效');
                    }
            }
            // 获取选中区域
            gDraw.selectedRect = { l:0, t:0, w:0, h:0 };
            // 设置选中
            gDraw.selected = false;
            // 控件选中
            gDraw.ctrlselected = { selected: false, status: 0 };
        }
        // 检查是否为缩放区域canvas
        else if(vCanvasEvent.id == HG.$Set.allCanvasID.zoomBar) {
            // 当前不做任何处理
        }
    },
    //-----------------------------------------------------------------------------
    // 绘制画布的鼠标事件 移动
    //
    mouseMoveEvent: function (event) {
        // 获取clientX, Y
        let vClientX = event.clientX /gDraw.domZoom;
        let vClientY = event.clientY /gDraw.domZoom;
        let vOffsetX = 0;
        let vOffsetY = 0;
        // canvas event
        let vCanvasEvent = { id: HG.$Set.allCanvasID.main };
        
        // 检查键盘、鼠标事件的源对象 
        if (!cHMIEvents.checkEventSourceValid(event, vCanvasEvent)) {
            // 无效操作控件
            return;
        }

        // 检查是否有效
        if (vClientX == 'undefined' || isNaN(vClientX)) {
            vClientX = window.event.clientX /gDraw.domZoom;
        }
        if (vClientY == 'undefined' || isNaN(vClientY)) {
            vClientY = window.event.clientY /gDraw.domZoom;
        }
        // scroll size
        vOffsetX = (window.pageXOffset !== undefined) ? window.pageXOffset : (document.documentElement || document.body.parentNode || document.body).scrollLeft;
        vOffsetY = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
        
        // 检查是否为主canvas
        if(vCanvasEvent.id == HG.$Set.allCanvasID.main) {
            // 计算偏移量
            vOffsetX = gDraw.rectCanvasArea.l - vOffsetX;
            vOffsetY = gDraw.rectCanvasArea.t - vOffsetY;

            // 选中点获取
            gDraw.selectedPointEd.x = (vClientX - vOffsetX) * gDraw.getRealSizeRate();
            gDraw.selectedPointEd.y = (vClientY - vOffsetY) * gDraw.getRealSizeRate();
            // 位置计算
            gDraw.selectedPointEd.x += gDraw.settings.scrollPosLeftRate   * gDraw.settings.width;
            gDraw.selectedPointEd.y += gDraw.settings.scrollPosTopRate    * gDraw.settings.height;
            // gDraw.selectedPointEd.x = gDraw.selectedPointEd.x /gDraw.domZoom;
            // gDraw.selectedPointEd.y = gDraw.selectedPointEd.y /gDraw.domZoom;
            // 检查是否为编辑模式，编辑模式下存在移动、改变尺寸操作
            if(gDraw.editingMode == true) {
                // 设置选中状态
                if (gDraw.selected) {
                    // 检查是否点击在控件上
                    if (!gDraw.ctrlselected.selected) {
                        // 获取选中区域
                        gDraw.selectedRect.l = Math.min(gDraw.selectedPointSt.x, gDraw.selectedPointEd.x);
                        gDraw.selectedRect.t = Math.min(gDraw.selectedPointSt.y, gDraw.selectedPointEd.y);
                        gDraw.selectedRect.w = Math.max(gDraw.selectedPointSt.x, gDraw.selectedPointEd.x) - gDraw.selectedRect.l;
                        gDraw.selectedRect.h = Math.max(gDraw.selectedPointSt.y, gDraw.selectedPointEd.y) - gDraw.selectedRect.t;
                    }
                    else {
                        // 控件选中时移动的偏移
                        gDraw.ctrlselectedMove.x = Math.round(gDraw.selectedPointEd.x - gDraw.ctrlselectedMove.x);
                        gDraw.ctrlselectedMove.y = Math.round(gDraw.selectedPointEd.y - gDraw.ctrlselectedMove.y);

                        // 检查选中的状态
                        if (0 == gDraw.ctrlselected.status) {
                            // 移动控件
                            HP.vHMIPrjFunc.moveSelectedCtrl(gDraw.ctrlselectedMove.x, gDraw.ctrlselectedMove.y);
                        }
                        else {
                            // 改变尺寸
                            HP.vHMIPrjFunc.changeSelectedCtrlSize(gDraw.ctrlselectedMove.x, gDraw.ctrlselectedMove.y, gDraw.ctrlselected.status);
                        }
                        // 动作处理
                        if(this.modifyUndoOperate) {
                            // 动作处理
                            this.ctrlsChanged = true;
                        }

                        // 移动的偏移复位
                        gDraw.ctrlselectedMove.x = gDraw.selectedPointEd.x;
                        gDraw.ctrlselectedMove.y = gDraw.selectedPointEd.y;
                    }
                    // 活动状态
                    gDraw.setEventActive();
                }
                else {
                    // 检查是否点击在选中的控件上
                    gDraw.ctrlselected = HP.vHMIPrjFunc.pointSelectedCtrl(Math.round(gDraw.selectedPointEd.x),
                                                                        Math.round(gDraw.selectedPointEd.y));
                    // 鼠标样式设置
                    gDraw.setCanvasCursor();
                }
            } else {
                // 检查是否在可执行的控件
                gDraw.onExecableCtrl = HP.vHMIPrjFunc.checkOnExecableCtrl(Math.round(gDraw.selectedPointEd.x),
                                                                          Math.round(gDraw.selectedPointEd.y));
                // 鼠标样式设置
                gDraw.setCanvasCursor();
            }
        }
        // 检查是否为缩放区域canvas
        else if(vCanvasEvent.id == HG.$Set.allCanvasID.zoomBar) {
            // 当前不做任何处理
        }
    },
    //-----------------------------------------------------------------------------
    // 绘制画布中的鼠标中键滚动事件
    //
    mouseWheelEvent: function (event) {
        // canvas event
        let vCanvasEvent = { id: HG.$Set.allCanvasID.main };
        // 窗口鼠标滚动句柄
        let wheelFunc = null;
        // 滚动位置
        let position = 0;
        // shift键是否按下
        let shiftDown = false;
        
        // 检查键盘、鼠标事件的源对象 
        if (!cHMIEvents.checkEventSourceValid(event, vCanvasEvent)) {
            // 无效操作控件
            return;
        }

        // 滚动位置
        position    = event.deltaY * 0.1;
        // shift
        shiftDown   = event.shiftKey;

        // 检查是否为主canvas
        if(vCanvasEvent.id == HG.$Set.allCanvasID.main) {
            // Apply scale transform
            // 检查改变窗口的大小的操作
            for(let idx = 0; idx < gEventsGlobalAttributes.wheelWndFuncs.length; idx++) {
                // 检查是否有效
                wheelFunc = gEventsGlobalAttributes.wheelWndFuncs[idx];
                // 检查是否有效
                if(null != wheelFunc) {
                    // 执行操作
                    wheelFunc(position, shiftDown);
                }
            }
        }
        // 检查是否为缩放区域canvas
        else if(vCanvasEvent.id == HG.$Set.allCanvasID.zoomBar) {
            // 当前不做任何处理
        }
    },
    //-----------------------------------------------------------------------------
    // 绘制画布的按键事件 按下
    //
    keyDownEvent: function (event) {
        // 获取keycode
        let vKeyCode    = event.keyCode;
        let vCtrlCode   = event.ctrlKey;
        let vCancelKey  = false;
        // 操作源对象
        var vSourceCtrl = null;
        // 获取X, Y偏移
        let vX = 0, vY = 0;

        // 检查是否有效按键
        if (vKeyCode == 'undefined' || isNaN(vKeyCode)) {
            vKeyCode = window.event.keyCode;
        }
        // 检查是否有效按键
        if (vCtrlCode == 'undefined' || isNaN(vCtrlCode)) {
            vCtrlCode = window.event.ctrlKey;
        }

        //
        // 回车操作
        //
        if(VirtualKeyType.VK_ENTER == vKeyCode) {
            // 检查是否存在
            if(gEventsGlobalAttributes.returnFunc) {
                // 获取鼠标操作源对象
                if (event.target) {
                    vSourceCtrl = event.target;
                }
                else if (event.srcElement) {
                    vSourceCtrl = event.srcElement;
                }
                // 执行回车事件
                gEventsGlobalAttributes.returnFunc(vSourceCtrl);
                // 返回
                return;
            }
        }

        // 检查上次操作的对象 
        if (!gEventsGlobalAttributes.checkLastOperateIsCanvas()) {
            // 检查Control是否按下
            if (vCtrlCode) {
                // 按键
                switch(vKeyCode)
                {
                case VirtualKeyType.VK_C:
                    // 清除复制内容
                    HP.vHMIPrjFunc.clearCopyControls();
                    break;
                case VirtualKeyType.VK_X:
                    // 清除复制内容
                    HP.vHMIPrjFunc.clearCopyControls();
                    break;
                }
            }
            // 上次操作的控件
            return;
        }

        // 移动控件
        switch(vKeyCode)
        {
        default:
            // 其他按键操作
            return;
        case VirtualKeyType.VK_LEFT:
            // 获取X, Y偏移
            vX = -2; vY = 0;
            // 取消按键
            vCancelKey = true;
            break;
        case VirtualKeyType.VK_UP:
            // 获取X, Y偏移
            vX = 0; vY = -2;
            // 取消按键
            vCancelKey = true;
            break;
        case VirtualKeyType.VK_RIGHT:
            // 获取X, Y偏移
            vX = 2; vY = 0;
            // 取消按键
            vCancelKey = true;
            break;
        case VirtualKeyType.VK_DOWN:
            // 获取X, Y偏移
            vX = 0; vY = 2;
            // 取消按键
            vCancelKey = true;
            break;
        case VirtualKeyType.VK_DELETE:
            // 删除选中控件
            HP.vHMIPrjFunc.deleteAllSelectedCtrls();
            break;
        case VirtualKeyType.VK_C:
            // 检查Control是否按下
            if (vCtrlCode) {
                // 复制
                HP.vHMIPrjFunc.copyAllSelectedCtrls();
            }
            break;
        case VirtualKeyType.VK_V:
            // 检查Control是否按下
            if (vCtrlCode) {
                // 粘贴
                HP.vHMIPrjFunc.pasteCtrls();
            }
            break;
        case VirtualKeyType.VK_X:
            // 检查Control是否按下
            if (vCtrlCode) {
                // 剪切
                HP.vHMIPrjFunc.cutAllSelectedCtrls();
            }
            break;
        }

        // 获取X, Y偏移
        if (vX != 0 || vY != 0) {
            // 改变位置
            HP.vHMIPrjFunc.changeSelectedCtrlSize(vX, vY, 5);
            // 活动状态
            gDraw.setEventActive();
        }

        // 取消按键
        if(vCancelKey) {
            // 取消按键
            event.keyCode;
        }
    },
    //-----------------------------------------------------------------------------
    // 绘制画布的按键事件 释放
    //
    keyUpEvent: function (event) {
        // 获取keycode
        var vKeyCode = event.keyCode;
        // 检查是否有效按键
        if (vKeyCode == 'undefined' || isNaN(vKeyCode)) {
            vKeyCode = window.event.keyCode;
        }
    },
    //-----------------------------------------------------------------------------
    // 窗口尺寸改变
    //
    wndResize: function () {
        // 窗口改变句柄
        let wndFunc = null;
        // 检查改变窗口的大小的操作
        for(let idx = 0; idx < gEventsGlobalAttributes.resizeWndFuncs.length; idx++) {
            // 检查是否有效
            wndFunc = gEventsGlobalAttributes.resizeWndFuncs[idx];
            // 检查是否有效
            if(null != wndFunc) {
                // 执行操作
                wndFunc(window.innerWidth, window.innerHeight);
            }
        }
    },
    //-----------------------------------------------------------------------------
    // 窗口关闭前
    //
    wndBeforeunload: function (e) {
        // 事件
        var eventSource = window.event || e;
        gWebSocket.closeWebSocket();
        // 检查是否保存
        if(HP.vHMIPrj.modified) {
            // 返回字符
            var vReturnValue = "";
            // 返回字符
            vReturnValue = "当前项目未保存，确定离开当前页面吗？";
            // 返回值
            eventSource.returnValue = vReturnValue;

            return vReturnValue;
        }
    },
};

//-----------------------------------------------------------------------------
// 绘制画布的操作属性
//
var gEventsGlobalAttributes = {
    // 上次操作的控件的tag名
    lastOperateCtrlTag: null,
    // 操作窗口尺寸改变时的操作
    resizeWndFuncs: [],
    // 操作窗口中滚动中间滚轮的操作
    wheelWndFuncs: [],
    // 回车按键按下时的对应操作
    returnFunc: null,
    //-----------------------------------------------------------------------------
    // 检查上次操作的控件对象是否为画布 
    //
    checkLastOperateIsCanvas: function () {
        // 上次操作的控件的tag名
        if(this.lastOperateCtrlTag == 'canvas') {
            return true;
        }
        return false;
    },
    //-----------------------------------------------------------------------------
    // 添加操作窗口尺寸改变时的操作 
    //
    addResizeFunc: function (resizeFunc) {
        // 添加操作窗口尺寸改变时的操作
        this.resizeWndFuncs.push(resizeFunc);
    },
    //-----------------------------------------------------------------------------
    // 添加操作窗口中滚动中间滚轮的操作
    //
    addWheelFunc: function (wheelFunc) {
        // 添加操作窗口中滚动中间滚轮的操作
        this.wheelWndFuncs.push(wheelFunc);
    },
    //-----------------------------------------------------------------------------
    // 添加回车按键按下时的对应操作
    //
    addReturnFunc: function (func) {
        // 回车按键按下时的对应操作
        this.returnFunc = func;
    },
    //-----------------------------------------------------------------------------
    // 移除回车按键按下时的对应操作
    //
    removeReturnFunc: function () {
        // 回车按键按下时的对应操作
        this.returnFunc = null;
    },
};

// 外部接口暴露
export default {
    events:     cHMIEvents,
    attributes: gEventsGlobalAttributes,
};