import axios from '../axios'

//获取不分页项目组列表
export const getProjectGroup = () => {
  return axios({
    method: "get",
    url: `projectGroup/userList`,
  })
}

//获取不分页项目组列表
export const getWarningPage = data => {
  return axios({
    method: "get",
    url: `alarmInfo/page?current=${data.current}&size=${data.size}&startDate=${data.startDate}&endDate=${data.endDate}&seriousAlarm=${data.seriousAlarm}&projectGroupId=${data.projectGroupId}`,
  })
}


//报警记录删除
export const delAlarm = data => {
  return axios({
    method: "delete",
    url: `alarmInfo/${data}`,
  })
}
export const getYsImage = data => {
  return axios({
    method: "get",
    url: `alarmInfo/ysImage/${data}`,
  })
}

//添加巡检记录
export const postAlarmInfoLog = data => {
  return axios({
    method: "post",
    url: `/alarmInfoLog`,
    data:data
  })
}
//修改巡检记录
export const putAlarmInfoLog = data => {
  return axios({
    method: "put",
    url: `/alarmInfoLog`,
    data:data
  })
}
//获取巡检记录
export const getAlarmInfoLog = data => {
  return axios({
    method: "get",
    url: `/alarmInfoLog/${data}`,
  })
}
//删除巡检记录
export const delAlarmInfoLog = data => {
  return axios({
    method: "delete",
    url: `/alarmInfoLog/${data}`,
  })
}
//下载历史记录
export const downLoadData = data => {
  return axios({
    method: "post",
    url: `/alarmInfo/download`,
    data
  })
}