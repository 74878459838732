/*********************************************************************
*                         Hangzhou Lingzhi                           *
*                        Internet of Things                          *
**********************************************************************
*                                                                    *
*           (c) 2018 - 2021 Hangzhou Lingzhi Corporation.            *
*                       All rights reserved.                         *
*                                                                    *
*       www.lzlinks.com     Support: 4145247472@qq.com               *
*                                                                    *
**********************************************************************
*                                                                    *
*     DATE                   NAME                  DESCRIPTION       *
*   2021/1/1                 WuDi                    Created.        *
*                                                                    *
**********************************************************************
*/
import Vue from 'vue';
import element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; //element-UI原主题
import store from '@/store/store';
import axios from 'axios';
import VueAMap from 'vue-amap';
import SlideVerify from 'vue-monoplasty-slide-verify';
import Router from 'vue-router';
import vuex from 'vuex';
import App from './App.vue';
import './assets/icon/iconfont.css';
import directive from "./util/scroll";
import * as dayjs from 'dayjs'
import api from './http';
import router from './router/index';
// import '../src/theme/default/theme/index.css';//拓展主题（多种可选的动态配置）
import HighchartsVue from 'highcharts-vue'
import './util/common.css';
import moment from 'moment'

import highcharts from 'highcharts/highstock';
import highcharts3d from 'highcharts/highcharts-3d'
import sunburst from 'highcharts/modules/sunburst';
// /*引入其他需要的*/
highcharts3d(highcharts) //3D图引入
sunburst(highcharts)

import VueKindEditor from "kindeditor";

Vue.use(VueKindEditor);



/*挂在到原型使用*/
Vue.prototype.$highcharts = highcharts;
Vue.prototype.dayjs=dayjs
Vue.prototype.$axios = axios


Vue.config.productionTip = false
Vue.directive("scroll", directive);
Vue.use(Router)
Vue.use(vuex);
Vue.use(api);
Vue.use(element)
Vue.use(SlideVerify)
Vue.use(HighchartsVue)
Vue.use(VueAMap)
Vue.use(moment)
window._AMapSecurityConfig = {
  securityJsCode:'cc8efd74ddf6daa79c1cab59691a57e7',
}

VueAMap.initAMapApiLoader({
  key: "0ff31d3982cb6cc49206b5afb7bd6121",
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor",
    "AMap.Geolocation", //定位
    "AMap.Weather", //天气
    "AMap.CitySearch", // 城市
    "AMap.MarkerClusterer",
    "AMap.ConvertFrom",
    "AMap.LazyAMapApiLoaderInstance"
  ],
  uiVersion: '1.0',
  v: "1.4.4"
});
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
/*************************** End of file ****************************/