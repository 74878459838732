import api from './api';

const install = Vue => {
    if (install.installed) {
        return;
    }
    install.installed = true;
    Object.defineProperties(Vue.prototype, {
        //注意：此处挂在在Vue圆形的$api对象上
        $api: {
            get() {
                return api
            }
        }
    })
}

export default install

