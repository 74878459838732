import axios from '../axios'

//创建服务器
export const createServer = data => {
    return axios({
        method: "post",
        url: `server`,
        data: data,

    })
}

//编辑服务器
export const editServer = data => {
    return axios({
        method: "put",
        url: `server`,
        data: data,
    })
}

//删除服务器
export const delServer = data => {
    return axios({
        method: "delete",
        url: `server/${data}`,
    })
}

// 查询服务器详情
export const getServer = data => {
    return axios({
        method: "get",
        url: `server/${data}`,
    })
}

//查看服务器列表（分页）
export const getServerPage = data => {
    return axios({
        method: "get",
        url: `server/page?current=${data.current}&size=${data.size}&serverName=${data.serverName}&serverHost=${data.serverHost}`,
    })
}


// 查询服务器列表
export const getServerList = () => {
    return axios({
        method: "get",
        url: `server/list?serverName=`,
        // url: `server/list?serverName=${data.serverName}`,
    })
}

