import axios from '../axios'

//获取分页周报列表
export const getTemplateWeeklyPage = (data) => {
  return axios({
    method: "get",
    url: `templateWeekly/pageList?current=${data.current}&size=${data.size}`,
  })
}
//获取周报模板详情
export const getTemplateWeekly = (data) => {
  return axios({
    method: "get",
    url: `templateWeekly/${data}`,
  })
}
//添加周报模板
export const postTemplateWeekly = (data) => {
  return axios({
    method: "post",
    url: `templateWeekly`,
    data:data,
  })
}
//修改周报模板
export const putTemplateWeekly = (data) => {
  return axios({
    method: "put",
    url: `templateWeekly`,
    data:data,
  })
}
//删除周报模板
export const delTemplateWeekly = (data) => {
  return axios({
    method: "delete",
    url: `templateWeekly/${data}`,
  })
}
//获取用户列表
export const getWeeklyUserList = (data) => {
  return axios({
    method: "get",
    url: `templateWeekly/userList/${data}`,
  })
}
//数据应用列表
export const getplanIdProtocolList = (data) => {
    return axios({
      method: "get",
      url: `dataApplication/daList?planId=${data}&dataConditionId=1`,
    })
  }
