import axios from '../axios'

export const editPlatform = data => {
    return axios({
        method: "put",
        url: `platform`,
        data: data
    })
}

// 根据userId，查询平台信息
export const getPlatform = data => {
    return axios({
        method: "get",
        url: `platform/${data}`,
    })
}

