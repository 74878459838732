// HMIPlanData 外部接口js
//
//  依赖
//      无
//
//  历史
//      创建    LEE     2021/03/19
//=============================================================================
//-----------------------------------------------------------------------------
// 方案数据
//
var gPlanData = {
    isThree:false,
    // 网关
    subGateways: [],
    // 设备列表
    subOriginals: [],
    // 参数列表
    subParameters: [],
    //非联动参数列表
    itfData:[],
    // 格式化的数据
    formatLabel: [],
    //-------------------------------------------------------------------------
    // 内部参数使用关键字
    //
    PlanDataInsideParamKeys: {
        // 内部参数值
        insideParamValue:       '__Inside_Param_Value__',
        // 内部参数标签
        insideParamName:        '内部参数',
        // 内部寄存器值
        insideRegisterValue:    '__Inside_Register_Value__',
        // 内部寄存器标签
        insideRegisterName:     '内部寄存器',
        // 内部寄存器值
        insideRegVal_W807:      'W807',
        // 内部寄存器名
        insideRegName_W807:     '当前登录权限',
    },
    // 重置数据
    resetData: function() {
        // 网关
        this.subGateways = [];
        // 设备列表
        this.subOriginals = [];
        // 参数列表
        this.subParameters = [];
    },
    // 获取项目的数据
    getAllData: function () {
        let vFormatLabel = null;
        this.formatLabel = [];    
        // 检查是否为空
        // if (this.formatLabel.length === 0) {
        if (this.isThree) {
            // 格式化数据
            for (let vIdx = 0; vIdx < this.subGateways.length; vIdx++) {
                // 获取
                vFormatLabel = {
                    value: this.subGateways[vIdx].gatewayId,
                    label: this.subGateways[vIdx].name,
                    children: [],
                };
                 
                // 添加项目数据 Original
                this.getOriginal(vFormatLabel.children, this.subGateways[vIdx].gatewayId);
                // 检查是否为空         
                if (vFormatLabel.children.length > 0) {
                    // 添加
                    this.formatLabel.push(vFormatLabel);
                }         
            }
            // 获取内部参数
            this.formatLabel.push(this.getInsideParameteres());
        } else {
            let paramArr = []
            this.itfData.forEach(ele => {
                paramArr.push({
                    label: ele.attributeName,
                    value: ele.attributeId
                })
            });
            this.formatLabel = paramArr
        }
        // } 
        
        return this.formatLabel;
    },
    // 获取项目的数据 Original
    getOriginal: function (vOriginalArray, vSubGatewayId) {
        let vOriginal = null;
        // 格式化数据
        for(let vIdx = 0; vIdx < this.subOriginals.length; vIdx++) {
            // 检查是否一致
            if(vSubGatewayId === this.subOriginals[vIdx].gatewayId) {
                // 获取
                vOriginal = {
                    value: this.subOriginals[vIdx].subOriginalId,
                    label: this.subOriginals[vIdx].name,
                    children: [], 
                };
                // 添加项目数据 Parameter
                this.getParameter(vOriginal.children, this.subOriginals[vIdx].subOriginalId);
                // 检查是否为空
                if(vOriginal.children.length > 0) {
                    // 添加
                    vOriginalArray.push(vOriginal);
                }
            }
        }
    },
    // 获取项目的数据 Parameter
    getParameter: function (vParameterArray, vsubOriginalid) {
        let vParameter = null;
        // 格式化数据
        for(let vIdx = 0; vIdx < this.subParameters.length; vIdx++) {
            // 检查是否一致
            if(vsubOriginalid === this.subParameters[vIdx].subOriginalId) {
                // 获取
                vParameter = {
                    value: this.subParameters[vIdx].paramId,
                    label: this.subParameters[vIdx].name,
                    paramTypeId: this.subParameters[vIdx].paramTypeId,
                };
                // 添加
                vParameterArray.push(vParameter);
            }
        }
    },
    // 添加内部参数
    getInsideParameteres: function() {
        // 获取内部参数
        var vInsideParam = {
            // 内部参数值
            value: this.PlanDataInsideParamKeys.insideParamValue,
            // 内部参数标签
            label: this.PlanDataInsideParamKeys.insideParamName,
            // 内部数据
            children: [], 
        };
        // 添加内部寄存器
        this.getInsideRegisters(vInsideParam.children);

        return vInsideParam;
    },
    // 添加内部寄存器
    getInsideRegisters: function(vParameterArray) {
        // 获取内部寄存器
        var vInsideRegs = {
            // 内部寄存器值
            value: this.PlanDataInsideParamKeys.insideRegisterValue,
            // 内部寄存器标签
            label: this.PlanDataInsideParamKeys.insideRegisterName,
            // 内部寄存器
            children: [], 
        };
        // 获取详细的寄存器值
        this.getInsideDetailRegisters(vInsideRegs.children);
        // 添加内部参数数组
        vParameterArray.push(vInsideRegs);
    },
    // 添加详细的寄存器
    getInsideDetailRegisters: function(vParameterArray) {  
        // 添加详细的寄存器
        // W807 当前登录权限
        vParameterArray.push({
            value: this.PlanDataInsideParamKeys.insideRegVal_W807,
            label: this.PlanDataInsideParamKeys.insideRegName_W807,
            paramTypeId: 1,
        });
    },
};

// 外部接口暴露
export default gPlanData;