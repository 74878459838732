import axios from '../axios';

export const ruleApi = () => {
  return axios({
    method: "get",
    url: `menu/tree`,
  })
}

//校验token
export const checkToken = () => {
  return axios({
    method: "get",
    url: `auth/check`,
  })
}

//上传图片接口
export const uploadImg = data => {
  return axios({
    headers: {
      "Content-Type": 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    method: "post",
    url: `img`,
    data: data
  })
}

