import axios from '../axios'

//添加告警解决方案
export const alarmSolution = data => {
  return axios({
    method: "post",
    url: `alarmSolution`,
    data:data,
  })
}
//查询解决方案
export const getAlarmSolution = data => {
  return axios({
    method: "get",
    url: `alarmSolution/${data}`,
    
  })
}
//修改告警解决方案
export const putAlarmSolution = data => {
  return axios({
    method: "put",
    url: `alarmSolution`,
    data:data,
  })
}
//删除告警解决方案
export const delAlarmSolution = data => {
  return axios({
    method: "delete",
    url: `alarmSolution/${data}`,
   
  })
}
//获取分页告警解决方案
export const getPageAlarmSolution = data => {
  return axios({
    method: "get",
    url: `/alarmSolution/page?size=${data.size}&current=${data.current}&solutionName=${data.solutionName}&planId=${data.planId}`,
   
  })
}
//获取解决方案列表
export const getlistAlarmSolution = data => {
  return axios({
    method: "get",
    url: `alarmSolution/list/${data}`,
   
  })
}
//图片删除接口
export const imgDel = data => {
  return axios({
    method: "delete",
    url: `alarmSolution/imgDel`,
    data:data,
  })
}
//图片删除接口
export const uploadImg = data => {
  return axios({
    method: "post",
    url: `alarmSolution/upload`,
    data:data,
  })
}

