import axios from '../axios'

export const login = data => {
  return axios({
    method: "post",
    url: `login`,
    data: data,
    headers: {
      "Content-Type": 'application/x-www-form-urlencoded;charset=UTF-8'
    },
  })
}

//手机号获取验证码
export const mobileGetCode = data => {
  return axios({
    method: "get",
    url: `/sms/mobileSms/${data}`,
  })
}

//账号获取验证码
export const accountGetCode = data => {
  return axios({
    method: "get",
    url: `/sms/userSms/${data}`,
  })
}

//用户激活 
export const activation = data => {
  return axios({
    method: "put",
    url: `auth/activation`,
    data: data
  })
}

//修改密码
export const changePassword = data => {
  return axios({
    method: "put",
    url: `auth`,
    data: data
  })
}

// 根据path，查询平台信息
export const getPlatform = data => {
  return axios({
    method: "get",
    url: `auth/${data}`,
  })
}