import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    token: '',
    tokenId:"",
    userId: "",
    routes: '',
    activeMenu: '',
    childrenMenu: [],
    treeList: [],
    permissionArr: [],
    path: "",
    rank: -2,
    pId: "",
    projectId: "",
    isEditor: false,
    loading: true,
    platType: "pc",
    name:"尊敬的平台客户",
    username: "",
    CancelTokenArr: [],
    openNumber:true,
  },
  mutations: {
    setopenNumber: (state, newtoken) => {
      state.openNumber = newtoken;
    },
    //写入方法
    setToken: (state, newtoken) => {
      state.token = newtoken;
    },
    setTokenId: (state, newtokenId) => {
      state.tokenId = newtokenId;
    },
    setUserId: (state, newUserId) => {
      state.userId = newUserId;
    },
    setRoutes: (state, newRoutes) => {
      state.routes = newRoutes;
    },
    setActiveMenu: (state, newActiveMenu) => {
      state.activeMenu = newActiveMenu;
    },
    setChildrenMenu: (state, newChildrenMenu) => {
      state.childrenMenu = newChildrenMenu;
    },
    setTreeList: (state, newTreeList) => {
      state.treeList = newTreeList;
    },
    setPermissionArr: (state, newPermissionArr) => {
      state.permissionArr = newPermissionArr;
    },
    setPath: (state, newPath) => {
      state.path = newPath;
    },
    setRank: (state, newRank) => {
      state.rank = newRank || -2;
    },
    setPId: (state, newPId) => {
      state.pId = newPId;
    },
     setProjectId: (state, newProjectId) => {
      state.projectId = newProjectId;
    },
    setIsEdit: (state, newIsEdit) => {
      state.isEditor = newIsEdit;
    },
    setLoading: (state, newLoading) => {
      state.loading = newLoading;
    },
    setPlatType: (state, newPlatType) => {
      state.platType = newPlatType;
    },
    setName: (state, newName) => {
      state.name = newName;
    },
    setUsername: (state, newUsername) => {
      state.username = newUsername;
    },
    addCancelToken: (state, cancel)=>{
      if (!state.CancelTokenArr) {
        state.CancelTokenArr=[]
      }
      if (cancel) {
        state.CancelTokenArr.push(cancel)
      }
    },
   //取消请求
    clearCancelToken:(state)=>{
     state.CancelTokenArr.forEach((item) => {
       if (item) {
         item()
       }
     })
      state.CancelTokenArr=[]
    }
  },
  modules: {
  }
})

export default store
