// HMIGlobal 全局常量管理js
//
//  依赖
//
//  历史
//      创建    LEE     2021/01/11
//=============================================================================
//-----------------------------------------------------------------------------
// 页面类型常量定义
//
const PageType = {
  // 错误的页面类型
  error:  0,
  // 空白页面
  blank:  1,
};
//-----------------------------------------------------------------------------
// 控件类型常量定义
//
const ControlType = {
  // 错误的控件类型
  error:    0,
  // 直线控件
  line:     1,
  // 矩形控件
  rect:     2,
  // 圆控件
  circle:   3,
  // 弧控件
  arc:      4,
  // 文本控件
  text:     5,
  // 指示灯
  light:    6,
  // 日期时间显示
  time:     7,
  // 位按钮
  bbtn:     8,
  // 字按钮
  wbtn:     9,
  // 页面按钮
  pbtn:     10,
  // 图片控件
  pic:      11,
  // 输入控件
  num:      12,
  // 文数对应控件
  textLst:  13,
  // 曲线控件
  curves:   14,
  // 柱状图控件
  columns:  15,
  // 饼图控件
  pies:     16,
  // 历史曲线
  historyCurves: 17,
  // 报警记录
  warningTable: 18,
  //仪表盘
  clockDial:19,
  //数据表格
  dataTable:20,

  /* LEE 2022/04/24 以下为PC端上传专用控件 可以不添加到工具栏中. */
  // 进度条控件
  progressBar:  21,
  // 图片数组对应控件
  pictureList:  22,
  // 图片仪表盘控件
  panelPic:     23,
};
//-----------------------------------------------------------------------------
// 定义接口和ID对照
//
const interfaceContrast = {
  1: "实时数据",
  2: "项目详情内容",
  3: "历史曲线",
  4: "报警列表",
}
//-----------------------------------------------------------------------------
// 操作常量定义
//
const OperateType = {
  // 错误的操作类型
  error:  0,
  // 撤销
  undo:   1,
  // 重做
  redo:   2,
  // 剪切
  cut:    3,
  // 复制
  copy:   4,
  // 粘贴
  paste:  5,
  // 删除
  delete: 6,
  // 预览
  view:   7,
  // 保存
  save: 8,
};
//-----------------------------------------------------------------------------
// 布局常量定义
//
const LayoutType = {
  // 错误的布局类型
  error:      0,
  // 左对齐
  left:       1,
  // 右对齐
  right:      2,
  // 上对齐
  top:        3,
  // 下对齐
  bottom:     4,
  
  // 最顶层
  first:      5,
  // 最底层
  last:       6,
  // 上一层
  prev:       7,
  // 下一层
  next:       8,

  // 垂直居中
  vcenter:    9,
  // 水平居中
  hcenter:    10,
  // 垂直等距
  vsame:      11,
  // 水平等距
  hsame:      12,

  // 相同宽度
  samew:      13,
  // 相同高度
  sameh:      14,
  // 最大相同尺寸
  samesizel:  15,
  // 最小相同尺寸
  samesizem:  16,
};
//-----------------------------------------------------------------------------
// 面板类型常量定义
//
const PanelType = {
  // 错误的面板类型
  error:      0,
  // 页面属性面板
  page:       1,
  // 控件属性面板
  control:    2,
  // 多选控件属性面板
  mctrls:     3,
};
//-----------------------------------------------------------------------------
// 页面属性常量定义
//
const PageAttributeType = {
  // 错误的页面属性
  error:      '',
  // 页面名称
  name:       'name',
  // 页面宽度
  width:      'width',
  // 页面高度
  height:     'height',
  // 背景色
  bgColor:    'bgColor',
  // 背景图片
  bgImage:    'bgImage',
};
//-----------------------------------------------------------------------------
// 系统权限属性常量定义
//
const SystemPermissionsAttributeType = {
  // 错误的系统权限属性
  error:          '',
  // 系统密码1
  sysPwd1:        'sysPwd1',
  // 系统密码2
  sysPwd2:        'sysPwd2',
  // 系统密码3
  sysPwd3:        'sysPwd3',
  // 系统密码4
  sysPwd4:        'sysPwd4',
  // 系统密码5
  sysPwd5:        'sysPwd5',
  // 系统密码6
  sysPwd6:        'sysPwd6',
};
//-----------------------------------------------------------------------------
// 控件位置常量定义
//
const PosAttributeType = {
  // 错误的控件位置
  error:    '',
  // X
  x:        'x',
  // Y
  y:        'y',
  // W
  w:        'w',
  // H
  h:        'h',
};
//=============================================================================
// 属性类型的详细定义
//=============================================================================
//-----------------------------------------------------------------------------
// 属性类型常量定义
//
const AttrType = {
  // 错误的属性类型
  error:          0,
  // 布尔
  bool:           1,
  // 数值
  int:            2,
  // 颜色
  color:          3,
  // 字符串
  string:         4,
  // 图片
  image:          5,
  // 字体大小
  fontsize:       6,
  // 线段类型
  lineType:       7,
  // 日期时间类型
  timeType:       8,
  // 变量类型
  variable:       9,
  // 位按钮操作
  bbtnValueSet:   10,
  // 字按钮操作
  wbtnValueSet:   11,
  // 跳转页面
  linkPage:       12,
  // 显示预览状态
  viewStatus:     13,
  // 文数对应数据
  textLstData:    14,
  // 参数颜色数据
  paramsData:     15,
  //表格数据
  dataTable:      16,
  // 进度条方向
  progressBarMode:    17,
  // 操作权限选择
  optPermissionMode:  18,
};
//-----------------------------------------------------------------------------
// 类型的具体的定义
//
const AttrSelType = {
  // 线段类型常量定义
  LineStyleType: {
    // 错误的线段类型
    error:        0,
    // 水平线段
    lineH:        1,
    // 垂直线段
    lineV:        2,
    // 交叉线段
    lineCross1:   3,
    // 交叉线段
    lineCross2:   4,
  },
  // 时间日期类型常量定义
  TimeStyleType: {
    // 错误的时间日期类型
    error:        0,
    // 全日期时间
    fullDateTime: 1,
    // 仅日期显示
    onlyDate:     2,
    // 仅时间显示
    onlyTime:     3,
  },
  // 位按钮操作常量定义
  BbtnValueSetType: {
    // 错误的位按钮操作
    error:        0,
    // 设定为ON
    setON:        1,
    // 设定为OFF
    setOFF:       2,
    // 交替
    change:       3,
  },
  // 字按钮操作常量定义
  WbtnValueSetType: {
    // 错误的位按钮操作
    error:        0,
    // 增加
    add:          1,
    // 减少
    dec:          2,
    // 设常量
    setConst:     3,
  },
  // 进度条模式
  progressBarMode: {
    // 横向进度条
    horizontal:   0,
    // 纵向进度条
    vertical:     1,
  },
  // 进度显示模式
  progressDisplayMode: {
    // 不显示
    none:         0,
    // 百分比
    percent:      1,
    // 数值
    number:       2,
  },
  // 图片数值对应控件模式
  pictureListMode: {
    // 普通模式
    normal:         0,
    // 动画模式
    animation:      1,
  },
  // 操作权限模式
  operatePermissionMode: {
    // 无权限
    none:             0,
    // 系统权限1
    sysPermission01:  1,
    // 系统权限2
    sysPermission02:  2,
    // 系统权限3
    sysPermission03:  3,
    // 系统权限4
    sysPermission04:  4,
    // 系统权限5
    sysPermission05:  5,
    // 系统权限6
    sysPermission06:  6,
  },
};
//-----------------------------------------------------------------------------
// 所有选择类型的具体描述
//
const AttrSelTypeDesc = {
  // 布尔类型
  boolOptions: [
    { key: false,   text: '否' },
    { key: true,    text: '是' },
  ],
  // 字体大小
  fontSizeOptions: [
    { key: 7,       text: '7' },
    { key: 8,       text: '8' },
    { key: 9,       text: '9' },
    { key: 10,      text: '10' },
    { key: 11,      text: '11' },
    { key: 12,      text: '12' },
    { key: 13,      text: '13' },
    { key: 14,      text: '14' },
    { key: 16,      text: '16' },
    { key: 18,      text: '18' },
    { key: 20,      text: '20' },
    { key: 22,      text: '22' },
    { key: 24,      text: '24' },
    { key: 26,      text: '26' },
    { key: 28,      text: '28' },
    { key: 36,      text: '36' },
    { key: 48,      text: '48' },
    { key: 72,      text: '72' },
    { key: 96,      text: '96' },
    { key: 120,     text: '120' },
    { key: 150,     text: '150' },
  ],
  // 线段类型
  lineTypeOptions: [
    { key: AttrSelType.LineStyleType.lineH,        text: '水平' },
    { key: AttrSelType.LineStyleType.lineV,        text: '垂直' },
    { key: AttrSelType.LineStyleType.lineCross1,   text: '交叉1' },
    { key: AttrSelType.LineStyleType.lineCross2,   text: '交叉2' },
  ],
  // 日期时间类型
  timeTypeOptions: [
    { key: AttrSelType.TimeStyleType.fullDateTime,  text: '全日期时间' },
    { key: AttrSelType.TimeStyleType.onlyDate,      text: '仅日期显示' },
    { key: AttrSelType.TimeStyleType.onlyTime,      text: '仅时间显示' },
  ],
  // 位按钮类型
  bbtnValueSetTypeOptions: [
    { key: AttrSelType.BbtnValueSetType.setON,      text: '设定为ON'   },
    { key: AttrSelType.BbtnValueSetType.setOFF,     text: '设定为OFF'  },
    { key: AttrSelType.BbtnValueSetType.change,     text: '交替'       },
  ],
  // 字按钮类型
  wbtnValueSetTypeOptions: [
    { key: AttrSelType.WbtnValueSetType.add,        text: '增加'       },
    { key: AttrSelType.WbtnValueSetType.dec,        text: '减少'       },
    { key: AttrSelType.WbtnValueSetType.setConst,   text: '设常量'     },
  ],
  // 进度条模式
  progressBarModeOptions: [
    // 横向进度条
    { key: AttrSelType.progressBarMode.horizontal,  text: '横向'       },
    // 纵向进度条
    { key: AttrSelType.progressBarMode.vertical,    text: '纵向'       },
  ],
  // 操作权限选择类型
  optPermissionModeOptions: [
    { key: AttrSelType.operatePermissionMode.none,              text: '无'        },
    { key: AttrSelType.operatePermissionMode.sysPermission01,   text: '系统权限1'  },
    { key: AttrSelType.operatePermissionMode.sysPermission02,   text: '系统权限2'  },
    { key: AttrSelType.operatePermissionMode.sysPermission03,   text: '系统权限3'  },
    { key: AttrSelType.operatePermissionMode.sysPermission04,   text: '系统权限4'  },
    { key: AttrSelType.operatePermissionMode.sysPermission05,   text: '系统权限5'  },
    { key: AttrSelType.operatePermissionMode.sysPermission06,   text: '系统权限6'  },
  ],
};
//=============================================================================
// 项目的全局设置开始
//=============================================================================
//-----------------------------------------------------------------------------
// 项目全局
//
const $Set = {
  //-----------------------------------------------------------------------------
  // 注册画布ID
  //
  allCanvasID: {
    main:     'HMIMainCanvas',
    zoomBar:  'HMIZoomBarCanvas',
  },
  //-----------------------------------------------------------------------------
  // 全局使用的项目状态
  //
  projectStatus: {
    // 工具栏的状态改变
    toolbarStatus: false,
  },
  //-----------------------------------------------------------------------------
  // 注册控件类型
  //
  allcontrols: [
      { idx: 0,   type: ControlType.line,           name: '线段控件',  image: require('@/assets/img/icon-line.png')     },
      { idx: 1,   type: ControlType.rect,           name: '矩形控件',  image: require('@/assets/img/icon-rect.png')     },
      { idx: 2,   type: ControlType.circle,         name: '圆控件',    image: require('@/assets/img/icon-circle.png')   },
      { idx: 3,   type: ControlType.arc,            name: '弧控件',    image: require('@/assets/img/icon-arc.png')      },
      { idx: 4,   type: ControlType.text,           name: '文本控件',  image: require('@/assets/img/icon-text.png')     },
      { idx: 5,   type: ControlType.pic,            name: '图片',      image: require('@/assets/img/icon-pic.png')      },
      { idx: 6,   type: ControlType.light,          name: '指示灯',    image: require('@/assets/img/icon-light.png')    },
      { idx: 7,   type: ControlType.time,           name: '时间显示',  image: require('@/assets/img/icon-time.png')     },
      { idx: 8,   type: ControlType.bbtn,           name: '位按钮',    image: require('@/assets/img/icon-bbtn.png')     },
      { idx: 9,   type: ControlType.wbtn,           name: '字按钮',    image: require('@/assets/img/icon-wbtn.png')     },
      { idx:10,   type: ControlType.pbtn,           name: '页面按钮',  image: require('@/assets/img/icon-pbtn.png')     },
      { idx:11,   type: ControlType.num,            name: '数值输入',  image: require('@/assets/img/icon-number.png')   },
      { idx:12,   type: ControlType.textLst,        name: '文数对应',  image: require('@/assets/img/icon-textlst.png')  },
      // { idx:13,   type: ControlType.curves,         name: '曲线控件',  image: require('@/assets/img/icon-curves.png')   },
      // { idx:14,   type: ControlType.columns,        name: '柱状图控件',image: require('@/assets/img/icon-columns.png')  },
      // { idx:15,   type: ControlType.pies,           name: '饼图控件',  image: require('@/assets/img/icon-pie.png')      },
      { idx: 16, type: ControlType.historyCurves, name: '历史曲线', image: require('@/assets/img/icon-history-curves.png') },
      { idx:17,   type: ControlType.warningTable,  name: '报警记录',  image: require('@/assets/img/alarm.png')},
      {idx:18,  type:ControlType.clockDial,       name:'仪表盘',   image: require('@/assets/img/dashboard.png')},
      {idx:19,  type:ControlType.dataTable,       name:'数据表格',   image: require('@/assets/img/dataTable.png')},  
    ],
  //-----------------------------------------------------------------------------
  // 所有的编辑操作
  //
  allOperates: [
      { idx: 0,   type: OperateType.undo,   name: '撤销',      image: require('@/assets/img/icon-undo.png'),    imgGrey: require('@/assets/img/icon-undo-grey.png') },
      { idx: 1,   type: OperateType.redo,   name: '重做',      image: require('@/assets/img/icon-redo.png'),    imgGrey: require('@/assets/img/icon-redo-grey.png') },
      { idx: 2,   type: OperateType.cut,    name: '剪切',      image: require('@/assets/img/icon-cut.png'),     imgGrey: require('@/assets/img/icon-cut-grey.png') },
      { idx: 3,   type: OperateType.copy,   name: '复制',      image: require('@/assets/img/icon-copy.png'),    imgGrey: require('@/assets/img/icon-copy-grey.png') },
      { idx: 4,   type: OperateType.paste,  name: '粘贴',      image: require('@/assets/img/icon-paste.png'),   imgGrey: require('@/assets/img/icon-paste-grey.png') },
      { idx: 5,   type: OperateType.delete, name: '删除',      image: require('@/assets/img/icon-delete.png'),  imgGrey: require('@/assets/img/icon-delete-grey.png') },
      { idx: 6,   type: OperateType.view,   name: '预览',      image: require('@/assets/img/icon-view.png'),    imgGrey: null },
      { idx: 7,   type: OperateType.save,   name: '保存',      image: require('@/assets/img/icon-save.png'),    imgGrey: null },
  ],
  //-----------------------------------------------------------------------------
  // 所有的布局操作
  //
  allLayout: [
      { idx: 0,   type: LayoutType.left,      name: '左对齐',    image: require('@/assets/img/icon-align-left.png') },
      { idx: 1,   type: LayoutType.right,     name: '右对齐',    image: require('@/assets/img/icon-align-right.png') },
      { idx: 2,   type: LayoutType.top,       name: '上对齐',    image: require('@/assets/img/icon-align-top.png') },
      { idx: 3,   type: LayoutType.bottom,    name: '下对齐',    image: require('@/assets/img/icon-align-bottom.png') },
      { idx: 4,   type: LayoutType.first,     name: '最顶层',    image: require('@/assets/img/icon-align-first.png') },
      { idx: 5,   type: LayoutType.last,      name: '最底层',    image: require('@/assets/img/icon-align-last.png') },
      { idx: 6,   type: LayoutType.prev,      name: '上一层',    image: require('@/assets/img/icon-align-prev.png') },
      { idx: 7,   type: LayoutType.next,      name: '下一层',    image: require('@/assets/img/icon-align-next.png') },
      { idx: 8,   type: LayoutType.vcenter,   name: '垂直居中',  image: require('@/assets/img/icon-align-vcenter.png') },
      { idx: 9,   type: LayoutType.hcenter,   name: '水平居中',  image: require('@/assets/img/icon-align-hcenter.png') },
      { idx: 10,  type: LayoutType.vsame,     name: '垂直等距',  image: require('@/assets/img/icon-align-vsame.png') },
      { idx: 11,  type: LayoutType.hsame,     name: '水平等距',  image: require('@/assets/img/icon-align-hsame.png') },
      { idx: 12,  type: LayoutType.samew,     name: '相同宽度',  image: require('@/assets/img/icon-align-samew.png') },
      { idx: 13,  type: LayoutType.sameh,     name: '相同高度',  image: require('@/assets/img/icon-align-sameh.png') },
      { idx: 14,  type: LayoutType.samesizel, name: '最大尺寸',  image: require('@/assets/img/icon-align-samesizel.png') },
      { idx: 15,  type: LayoutType.samesizem, name: '最小尺寸',  image: require('@/assets/img/icon-align-samesizem.png') },
  ],
  //-----------------------------------------------------------------------------
  // 初始页面数据
  //
  allPagesData: [
      { type: PageType.blank, idx: -1, name: '', bgColor: '#FFFFFF', bgImage: '', ctrls: [], selectedCnt: 0, offside: { x: 10, y: 8 } },
    ],
  //-----------------------------------------------------------------------------
  // 初始控件数据
  //
  allCtrlsData: [
      { type: ControlType.line,   x: 0, y: 0, w: 500, h: 20,  selected: false, 
                                  foreColor: '#000000', lineType: AttrSelType.LineStyleType.lineH, lineWidth: 1 },
      { type: ControlType.rect,   x: 0, y: 0, w: 200, h: 150, selected: false, 
                                  foreColor: '#000000', bgColor: '#FFFFFF', lineWidth: 1 },
      { type: ControlType.circle, x: 0, y: 0, w: 200, h: 200, selected: false, 
                                  foreColor: '#000000', bgColor: '#FFFFFF', lineWidth: 1 },
      { type: ControlType.arc,    x: 0, y: 0, w: 200, h: 200, selected: false, 
                                  foreColor: '#000000', startAg: 0, endAg: 300, lineWidth: 1 },
      { type: ControlType.text,   x: 0, y: 0, w: 200, h: 40,  selected: false, 
                                  foreColor: '#000000', fontName: '宋体', fontSize: 12, text: '新文本字符' },
      { type: ControlType.pic,    x: 0, y: 0, w: 600, h: 400, selected: false, 
                                  address: 'bg.png'},
      { type: ControlType.light,  x: 0, y: 0, w: 128, h: 128, selected: false, 
                                  off: 'light-off.png', on: 'light-on.png', 
                                  param: '', viewStatus: 0 , interfaceId: 1, multiple:false},
      { type: ControlType.time,   x: 0, y: 0, w: 300, h: 50,  selected: false, 
                                  foreColor: '#000000', fontName: '宋体', fontSize: 12, timeType: 
                                  AttrSelType.TimeStyleType.fullDateTime },
      { type: ControlType.bbtn,   x: 0, y: 0, w: 180, h: 68,  selected: false, optPermission: 0,
                                  foreColor: '#000000', fontName: '宋体', fontSize: 12, 
                                  off: 'btn-off.png', on: 'btn-on.png', 
                                  offText: 'Off', onText: 'On', param: '', viewStatus: 0, 
                                  mode: AttrSelType.BbtnValueSetType.change , interfaceId:1, multiple:false},
      { type: ControlType.wbtn,   x: 0, y: 0, w: 180, h: 68,  selected: false, optPermission: 0,
                                  foreColor: '#000000', fontName: '宋体', fontSize: 12, 
                                  address: 'btn-normal.png', text: '字按钮', param: '', 
                                  mode: AttrSelType.WbtnValueSetType.add, cmdValue: 0 , interfaceId:1 , multiple:false},
      { type: ControlType.pbtn,   x: 0, y: 0, w: 180, h: 68,  selected: false, optPermission: 0,
                                  foreColor: '#000000', fontName: '宋体', fontSize: 12, 
                                  address: 'btn-normal.png', text: '页面按钮', linkPg: -1 },
      { type: ControlType.num,    x: 0, y: 0, w: 200, h: 50,  selected: false, optPermission: 0,
                                  foreColor: '#000000', fontName: '宋体', fontSize: 12, 
                                  border: true, borderColor: '#000000', bgColor: '#FFFFFF', 
                                  param: '', readonly: false, interfaceId:1 , multiple:false},
      { type: ControlType.textLst,x: 0, y: 0, w: 500, h: 50,  selected: false,
                                  fontName: '宋体', fontSize: 12, editable: false,
                                  border: true, borderColor: '#000000', bgColor: '#FFFFFF', 
                                  viewStatus: 0, param: '', listValue: [], listOther: null , interfaceId:1 , multiple:false},
      { type: ControlType.curves, x: 0, y: 0, w: 620, h: 400,  selected: false,
                                  fontName: '宋体', fontSize: 10, maxY: 100, minY: 0, unitY: 20, unitX: 100,
                                  foreColor: '#000000', unitColor: '#DDDDDD', bgColor: '#FFFFFF', 
                                  lblVisible: true, curvesData: [] },
      { type: ControlType.columns, x: 0, y: 0, w: 620, h: 400,  selected: false, fontName: '宋体', fontSize: 10, 
                                  maxY: 100, minY: 0, unitY: 20, foreColor: '#000000', unitColor: '#DDDDDD', 
                                  bgColor: '#FFFFFF', lblVisible: true, columnsData: [] },
      { type: ControlType.pies,   x: 0, y: 0, w: 620, h: 400,  selected: false, fontName: '宋体', fontSize: 10,
                                  bgColor: '#FFFFFF', lblVisible: true, 
                                  piesData: [] },
      { type: ControlType.historyCurves,  x: 0, y: 0, w: 620, h: 400,  selected: false, 
                                  fontName: '黑体', fontSize: 20, title: '历史曲线',
                                  transparent: false, bgColor: '#FFFFFF', param: '', interfaceId:3 , multiple: true},
      { type: ControlType.warningTable,  x: 0, y: 0, w: 1280, h: 400,  selected: false,
                                  fontName: '黑体', fontSize: 20, title: '报警记录',
                                  transparent: false, bgColor: '#FFFFFF', param: '', interfaceId:4 }, 
      { type: ControlType.clockDial,  x: 0, y: 0, w: 620, h: 400,  selected: false, 
                                    fontName: '黑体', fontSize: 20,scaleSize: 20, title: '仪表盘',min:0,max:100,colors:'#000',param: '',
                                    transparent: false,bgColor: '#FFFFFF',  interfaceId:1 , multiple: false,symbol:"°C"},                           
      { type: ControlType.dataTable,  x: 0, y: 0, w: 620, h: 400,  selected: false, dataTable:[],
                                    fontName: '黑体', fontSize: 20,scaleSize: 20, title: '数据表格',colors:'#515151',
                                    transparent: false,bgColor: '#FFFFFF',  interfaceId:1 , multiple: false}, 
      /* LEE 2022/04/24 以下为PC端上传专用控件 可以不添加到工具栏中. */
      { type: ControlType.progressBar, x:0, y:0, w:320, h:26, selected:false, max:100, min:0, limitUp:80, limitDn:20, 
                                    lineColor:'#FFFFFF', foreColor:'#0072BC', bgColor:'#FFFFFF', limitUpColor:'#EE1D24', limitDnColor:'#999999',
                                    mode:AttrSelType.progressBarMode.horizontal, bgImage:'', foreImage:'',
                                    prgDspMode:AttrSelType.progressDisplayMode.none, prgDspColor:'#000000',
                                    fontName: '黑体', fontSize: 20, param:'', interfaceId:1, multiple:false},
      // 图片数值对应控件
      { type: ControlType.pictureList, x:0, y:0, w:320, h:240, selected:false, viewStatus: 0, 
                                       mode: AttrSelType.pictureListMode.normal, 
                                       listValue:[], otherImage:'', param:'', interfaceId:1, multiple:false}, 
      // 图片数值对应控件
      { type: ControlType.panelPic, x:0, y:0, w:300, h:300, selected:false, picBG:null, picFT:null, 
                                    fontName:'黑体', fontSize:20, foreColor:'#000000', min:0, max:100, 
                                    startAngle:25, endAngle:335, floatCnt:0, param:'', interfaceId:1, multiple:false}, 
  ],
  //-----------------------------------------------------------------------------
  // 缩放图片缓存
  //
  zoomBarImgs: { 
    normal: require('@/assets/img/ZBAR_N.png'),
    grey:   require('@/assets/img/ZBAR_G.png'),
    size:   { cx: 425, cy: 18 },
  },
  //-----------------------------------------------------------------------------
  // 无图片缓存
  //
  noImgsData: { 
    src: '无图片',
    url: require('@/assets/img/NO-IMG.jpg'),
  },
  //-----------------------------------------------------------------------------
  // 图片缓存
  //
  allImgsData: [
      { idx: 0,  src: '',                   url: '',                                            img: null,   static: true},
      { idx: 1,  src: 'bg.png',             url: 'https://oss.cniot.fun/iot-system/sys-program/0-1.png',             img: null,   static: true },
      { idx: 2,  src: 'bga.png',            url: 'https://oss.cniot.fun/iot-system/sys-program/0-1.png',            img: null,   static: true },
      { idx: 3,  src: 'bgb.png',            url: 'https://oss.cniot.fun/iot-system/sys-program/0-1.png',            img: null,   static: true },
      { idx: 4,  src: 'light-off.png',      url: 'https://oss.cniot.fun/iot-system/sys-program/7-2.png',      img: null,   static: true },
      { idx: 5,  src: 'light-on.png',       url: 'https://oss.cniot.fun/iot-system/sys-program/7-4.png',       img: null,   static: true },
      { idx: 6,  src: 'btn-off.png',        url: 'https://oss.cniot.fun/iot-system/sys-program/9-1.png',        img: null,   static: true },
      { idx: 7,  src: 'btn-on.png',         url: 'https://oss.cniot.fun/iot-system/sys-program/9-3.png',         img: null,   static: true },
      { idx: 8,  src: 'btn-normal.png',     url: 'https://oss.cniot.fun/iot-system/sys-program/14-2.png',     img: null,   static: true },
      { idx: 9,  src: 'Button_Normal.png',  url: 'https://oss.cniot.fun/iot-system/sys-program/6-2.png',  img: null,   static: true },
      { idx:10,  src: 'Button_Hover.png',   url: 'https://oss.cniot.fun/iot-system/sys-program/6-1.png',   img: null,   static: true },
      { idx:11,  src: '19.jpg',             url: 'https://oss.cniot.fun/iot-system/sys-program/1-3.png',             img: null,   static: true },
      { idx:12,  src: '20.jpg',             url: 'https://oss.cniot.fun/iot-system/sys-program/1-4.png',             img: null,   static: true },
      { idx:13,  src: '21.jpg',             url: 'https://oss.cniot.fun/iot-system/sys-program/1-1.png',             img: null,   static: true },
      { idx:14,  src: '22.png',             url: 'https://oss.cniot.fun/iot-system/sys-program/2-4.png',             img: null,   static: true },
      { idx:15,  src: '23.png',             url: 'https://oss.cniot.fun/iot-system/sys-program/2-3.png',             img: null,   static: true },
      { idx:16,  src: '40.png',             url: 'https://oss.cniot.fun/iot-system/sys-program/2-1.png',             img: null,   static: true },
  ],
  //-----------------------------------------------------------------------------
  // 数据复制
  //
  allCopyData: {
      count: 0,
      sourcePg: null,
      ctrls: [],
  },
  //-----------------------------------------------------------------------------
  // 所有属性
  //
  allAttributes: {
      // 页面属性
      pageAttributes: [
          { name: PageAttributeType.name,     type: AttrType.string,     display: '页面名称'},
          { name: PageAttributeType.width,    type: AttrType.int,        display: '宽度',        readonly: true     },
          { name: PageAttributeType.height,   type: AttrType.int,        display: '高度',        readonly: true     },
          { name: PageAttributeType.bgColor,  type: AttrType.color,      display: '背景色'    },
          { name: PageAttributeType.bgImage,  type: AttrType.image,      display: '背景图片'  },
      ],
      // 系统权限
      sysPermissionsAttributes: [
          { name: SystemPermissionsAttributeType.sysPwd1, type: AttrType.string, display: '系统密码1' },
          { name: SystemPermissionsAttributeType.sysPwd2, type: AttrType.string, display: '系统密码2' },
          { name: SystemPermissionsAttributeType.sysPwd3, type: AttrType.string, display: '系统密码3' },
          { name: SystemPermissionsAttributeType.sysPwd4, type: AttrType.string, display: '系统密码4' },
          { name: SystemPermissionsAttributeType.sysPwd5, type: AttrType.string, display: '系统密码5' },
          { name: SystemPermissionsAttributeType.sysPwd6, type: AttrType.string, display: '系统密码6' },
      ],
      // 页面位置属性
      posAttributes: [
          { name: PosAttributeType.x,         type: AttrType.int,        display: 'X'     },
          { name: PosAttributeType.y,         type: AttrType.int,        display: 'Y'     },
          { name: PosAttributeType.w,         type: AttrType.int,        display: '宽度'   },
          { name: PosAttributeType.h,         type: AttrType.int,        display: '高度'   },
      ],
      // 控件属性
      ctrlAttributes: [
        {
          // 线段控件显示属性
          type: ControlType.line, 
          attributes:[
            { name: 'foreColor',  type: AttrType.color,     display: '线条颜色' },
            { name: 'lineType',   type: AttrType.lineType,  display: '线条类型' },
            { name: 'lineWidth',  type: AttrType.int,       display: '线条宽度' },
            
          ],
        },
        {
          // 矩形控件显示属性
          type: ControlType.rect, 
          attributes:[
            { name: 'foreColor', type: AttrType.color, display: '线条颜色' },
            { name: 'bgColor',   type: AttrType.color, display: '背景颜色' },
            { name: 'lineWidth', type: AttrType.int,   display: '线条宽度' },
          ],
        },
        {
          // 圆控件显示属性
          type: ControlType.circle, 
          attributes:[
            { name: 'foreColor', type: AttrType.color, display: '线条颜色' },
            { name: 'bgColor',   type: AttrType.color, display: '背景颜色' },
            { name: 'lineWidth', type: AttrType.int,   display: '线条宽度' },
          ],
        },
        {
          // 弧控件显示属性
          type: ControlType.arc,
          attributes:[
            { name: 'foreColor', type: AttrType.color,  display: '线条颜色' },
            { name: 'startAg',   type: AttrType.int,    display: '起始角度' },
            { name: 'endAg',     type: AttrType.int,    display: '结束角度' },
            { name: 'lineWidth', type: AttrType.int,    display: '线条宽度' },
          ],
        },
        {
          // 文本控件显示属性
          type: ControlType.text, 
          attributes:[
            { name: 'foreColor',  type: AttrType.color,     display: '显示颜色' },
            { name: 'fontName',   type: AttrType.string,    display: '字体名'   },
            { name: 'fontSize',   type: AttrType.fontsize,  display: '字体大小' },
            { name: 'text',       type: AttrType.string,    display: '文字'     },
          ],
        },
        {
          // 图片控件显示属性
          type: ControlType.pic, 
          attributes:[
            { name: 'address',    type: AttrType.image,     display: '图片' },
          ],
        },
        {
          // 指示灯控件显示属性
          type: ControlType.light, 
          attributes:[
            { name: 'off',        type: AttrType.image,     display: '状态0图片' },
            { name: 'on',         type: AttrType.image,     display: '状态1图片' },
            { name: 'viewStatus', type: AttrType.viewStatus,display: '状态预览'  },
            { name: 'param',      type: AttrType.variable,  display: '网关参数'  },
          ],
        },
        {
          // 日期时间控件显示属性
          type: ControlType.time, 
          attributes:[
            { name: 'foreColor',  type: AttrType.color,     display: '显示颜色' },
            { name: 'fontName',   type: AttrType.string,    display: '字体名'   },
            { name: 'fontSize',   type: AttrType.fontsize,  display: '字体大小' },
            { name: 'timeType',   type: AttrType.timeType,  display: '显示类型' },
         
          ],
        },
        {
          // 位按钮控件显示属性
          type: ControlType.bbtn, 
          attributes:[
            { name: 'optPermission',  type: AttrType.optPermissionMode,   display: '操作权限'   },
            { name: 'foreColor',      type: AttrType.color,               display: '显示颜色'   },
            { name: 'fontName',       type: AttrType.string,              display: '字体名'     },
            { name: 'fontSize',       type: AttrType.fontsize,            display: '字体大小'   },
            { name: 'off',            type: AttrType.image,               display: '状态0图片'  },
            { name: 'on',             type: AttrType.image,               display: '状态1图片'  },
            { name: 'offText',        type: AttrType.string,              display: '状态0文字'  },
            { name: 'onText',         type: AttrType.string,              display: '状态1文字'  },
            { name: 'viewStatus',     type: AttrType.viewStatus,          display: '状态预览'   },
            { name: 'param',          type: AttrType.variable,            display: '网关参数'   },
            { name: 'mode',           type: AttrType.bbtnValueSet,        display: '值设置'     },
          ],
        },
        {
          // 字按钮控件显示属性
          type: ControlType.wbtn, 
          attributes:[
            { name: 'optPermission',  type: AttrType.optPermissionMode,   display: '操作权限'   },
            { name: 'foreColor',  type: AttrType.color,         display: '显示颜色'   },
            { name: 'fontName',   type: AttrType.string,        display: '字体名'     },
            { name: 'fontSize',   type: AttrType.fontsize,      display: '字体大小'   },
            { name: 'address',    type: AttrType.image,         display: '显示图片'   },
            { name: 'text',       type: AttrType.string,        display: '显示文字'   },
            { name: 'param',      type: AttrType.variable,      display: '网关参数'   },
            { name: 'mode',       type: AttrType.wbtnValueSet,  display: '值设置'     },
            { name: 'cmdValue',   type: AttrType.int,           display: '操作值'     },
          ],
        },
        {
          // 页面按钮控件显示属性
          type: ControlType.pbtn, 
          attributes:[
            { name: 'optPermission',  type: AttrType.optPermissionMode,   display: '操作权限'   },
            { name: 'foreColor',  type: AttrType.color,     display: '显示颜色'   },
            { name: 'fontName',   type: AttrType.string,    display: '字体名'     },
            { name: 'fontSize',   type: AttrType.fontsize,  display: '字体大小'   },
            { name: 'address',    type: AttrType.image,     display: '显示图片'   },
            { name: 'text',       type: AttrType.string,    display: '显示文字'   },
            { name: 'linkPg',     type: AttrType.linkPage,  display: '跳转页面'   },
          ],
        },
        {
          // 数组输入显示控件属性
          type: ControlType.num, 
          attributes:[
            { name: 'optPermission',  type: AttrType.optPermissionMode,   display: '操作权限'   },
            { name: 'foreColor',  type: AttrType.color,     display: '显示颜色'   },
            { name: 'fontName',   type: AttrType.string,    display: '字体名'     },
            { name: 'fontSize',   type: AttrType.fontsize,  display: '字体大小'   },
            { name: 'border',     type: AttrType.bool,      display: '显示边框'   },
            { name: 'borderColor',type: AttrType.color,     display: '边框颜色'   },
            { name: 'bgColor',    type: AttrType.color,     display: '背景色'     },
            { name: 'param',      type: AttrType.variable,  display: '网关参数'   },
            { name: 'readonly',   type: AttrType.bool,      display: '只读'       },
          ],
        },
        {
          // 文数对应控件属性
          type: ControlType.textLst, 
          attributes:[
            { name: 'fontName',   type: AttrType.string,      display: '字体名'     },
            { name: 'fontSize',   type: AttrType.fontsize,    display: '字体大小'   },
            { name: 'border',     type: AttrType.bool,        display: '显示边框'   },
            { name: 'borderColor',type: AttrType.color,       display: '边框颜色'   },
            { name: 'bgColor',    type: AttrType.color,       display: '背景色'     },
            { name: 'viewStatus', type: AttrType.viewStatus,  display: '状态预览'   },
            { name: 'param',      type: AttrType.variable,    display: '网关参数'   },
            { name: 'listValue',  type: AttrType.textLstData, display: '列表值设置' },
          ],
        },
        {
          // 曲线控件属性
          type: ControlType.curves, 
          attributes:[
            { name: 'fontName',   type: AttrType.string,      display: '字体名'        },
            { name: 'fontSize',   type: AttrType.fontsize,    display: '字体大小'      },
            { name: 'foreColor',  type: AttrType.color,       display: '坐标轴颜色'    },
            { name: 'unitColor',  type: AttrType.color,       display: '刻度线颜色'    },
            { name: 'maxY',       type: AttrType.int,         display: '垂直最大刻度'  },
            { name: 'minY',       type: AttrType.int,         display: '垂直最小刻度'  },
            { name: 'unitY',      type: AttrType.int,         display: '垂直刻度值'    },
            { name: 'unitX',      type: AttrType.int,         display: '水平刻度值'    },
            { name: 'bgColor',    type: AttrType.color,       display: '背景色'        },
            { name: 'lblVisible', type: AttrType.bool,        display: '显示标签'      },
            { name: 'curvesData', type: AttrType.paramsData,  display: '曲线数据'      },
          ],
        },
        {
          // 柱状图控件属性
          type: ControlType.columns, 
          attributes:[
            { name: 'fontName',    type: AttrType.string,      display: '字体名'        },
            { name: 'fontSize',    type: AttrType.fontsize,    display: '字体大小'      },
            { name: 'foreColor',   type: AttrType.color,       display: '坐标轴颜色'    },
            { name: 'unitColor',   type: AttrType.color,       display: '刻度线颜色'    },
            { name: 'maxY',        type: AttrType.int,         display: '垂直最大刻度'  },
            { name: 'minY',        type: AttrType.int,         display: '垂直最小刻度'  },
            { name: 'unitY',       type: AttrType.int,         display: '垂直刻度值'    },
            { name: 'bgColor',     type: AttrType.color,       display: '背景色'        },
            { name: 'lblVisible',  type: AttrType.bool,        display: '显示标签'      },
            { name: 'columnsData', type: AttrType.paramsData,  display: '柱状图数据'    },
          ],
        },
        {
          // 饼图控件属性
          type: ControlType.pies, 
          attributes:[
            { name: 'fontName',   type: AttrType.string,      display: '字体名'        },
            { name: 'fontSize',   type: AttrType.fontsize,    display: '字体大小'      },
            { name: 'bgColor',    type: AttrType.color,       display: '背景色'        },
            { name: 'lblVisible', type: AttrType.bool,        display: '显示标签'      },
            { name: 'piesData',   type: AttrType.paramsData,  display: '饼图数据'      },
          ],
        },
        {
          // 历史曲线属性
          type: ControlType.historyCurves, 
          attributes:[
            { name: 'fontName',       type: AttrType.string,      display: '字体名'        },
            { name: 'fontSize',       type: AttrType.fontsize,    display: '字体大小'      },
            { name: 'title',          type: AttrType.string,      display: '标题'          },
            { name: 'transparent',    type: AttrType.bool,        display: '背景透明'      },
            { name: 'bgColor',        type: AttrType.color,       display: '背景色'        },
            { name: 'param',          type: AttrType.variable,    display: '历史曲线数据'  },
          ],
        },
        {
          // 报警记录属性
          type: ControlType.warningTable, 
          attributes:[
            { name: 'fontName',       type: AttrType.string,      display: '字体名'        },
            { name: 'fontSize',       type: AttrType.fontsize,    display: '字体大小'      },
            { name: 'transparent',    type: AttrType.bool,        display: '背景透明'      },
            { name: 'bgColor',        type: AttrType.color,       display: '背景色'        }]
        },
        {
          // 仪表盘属性
          type: ControlType.clockDial, 
          attributes:[
            { name: 'fontName',       type: AttrType.string,      display: '字体名'        },
            { name: 'fontSize',       type: AttrType.fontsize,    display: '字体大小'      },
            { name: 'title',          type: AttrType.string,      display: '标题'          },
            { name: 'transparent',    type: AttrType.bool,        display: '背景透明'      },
            { name: 'bgColor',        type: AttrType.color,       display: '背景色'        },
            { name: 'colors',         type: AttrType.color,       display: '字体色'        },
            { name: 'min',            type: AttrType.int,         display: '起始温度'        },
            { name: 'max',            type: AttrType.int,         display: '最大温度'        },
            { name: 'param',          type: AttrType.variable,    display: '仪表盘'  },
            { name: 'scaleSize',      type: AttrType.fontsize,    display: '刻度大小'  },
            { name: 'symbol',         type: AttrType.string,      display: '单位'  },
          ],
        },
        {
          // 数据表格属性
          type: ControlType.dataTable, 
          attributes:[
            { name: 'fontName',       type: AttrType.string,      display: '字体名'        },
            { name: 'fontSize',       type: AttrType.fontsize,    display: '字体大小'      },
            { name: 'title',          type: AttrType.string,      display: '标题'          },
            { name: 'transparent',    type: AttrType.bool,        display: '背景透明'      },
            { name: 'bgColor',        type: AttrType.color,       display: '背景色'        },
            { name: 'colors',         type: AttrType.color,       display: '字体色'        },
            { name: 'dataTable',      type: AttrType.dataTable,   display: '绑定变量'      },
          ],
        },
        /* LEE 2022/04/24 以下为PC端上传专用控件 可以不添加到工具栏中. */
        {
          // 进度条属性
          type: ControlType.progressBar, 
          attributes:[
            { name: 'max',            type: AttrType.int,               display: '最大值'        },
            { name: 'min',            type: AttrType.int,               display: '最小值'        },
            { name: 'limitUp',        type: AttrType.int,               display: '上限值'        },
            { name: 'limitDn',        type: AttrType.int,               display: '下限值'        },
            { name: 'lineColor',      type: AttrType.color,             display: '边框色'        },
            { name: 'foreColor',      type: AttrType.color,             display: '前景色'        },
            { name: 'bgColor',        type: AttrType.color,             display: '背景色'         },
            { name: 'limitUpColor',   type: AttrType.color,             display: '上限显示色'     },
            { name: 'limitDnColor',   type: AttrType.color,             display: '下限显示色'     },
            { name: 'bgImage',        type: AttrType.image,             display: '背景图片'       },
            { name: 'foreImage',      type: AttrType.image,             display: '前景图片'       },
            { name: 'mode',           type: AttrType.progressBarMode,   display: '进度条方向'     },
            { name: 'param',          type: AttrType.variable,          display: '网关参数'       },
          ],
        },
        /* LEE 2022/04/25 图片数值对应控件. */
        {
          // 图片数值对应控件
          type: ControlType.pictureList, 
          attributes:[
            { name: 'viewStatus',     type: AttrType.viewStatus,        display: '状态预览'       },
            { name: 'otherImage',     type: AttrType.image,             display: '其他图片'       },
            { name: 'param',          type: AttrType.variable,          display: '网关参数'       },
          ],
        },
      ],
  },
  //-----------------------------------------------------------------------------
  // 设置
  //
  allSettings: {
      // 选中框的设置
      selectedFrameSetting: {
          size:         11,
          halfSize:     5,
      },
      // 滚动条尺寸
      scrollSize: {
          scrollHMargin:      496,
          scrollHMarginExe:   16,
          scrollVMargin:      66,
          scrollVMarginExe:   16,
          // 下拉列表宽度
          ddlScrollBarWidth:  50,
      },
      // 复制粘贴用数量,名称
      ControlsCopyCnt: 'CopyCnt_C84F4A38C052452FB0F65DD947E5A664',
      ControlsCopyDat: 'CopyDat_C84F4A38C052452FB0F65DD947E5A664',
      // 替换复制数据
      copyDataReplace: false,
      // 复制
      copyControls: function(vCtrlCnt, vCtrl) {
        // 保存数据
        localStorage.setItem(this.ControlsCopyCnt, vCtrlCnt);
        localStorage.setItem(this.ControlsCopyDat, vCtrl);
      },
      // 获取粘贴控件数量
      pasteControlsCnt: function() {
        // 获取复制数据
        return window.localStorage.getItem(this.ControlsCopyCnt);
      },
      // 粘贴
      pasteControls: function() {
        // 获取复制数据
        return window.localStorage.getItem(this.ControlsCopyDat);
      },
      // 清除复制
      clearCopyCtrls: function() {
        // 清除复制
        localStorage.removeItem(this.ControlsCopyCnt);
        localStorage.removeItem(this.ControlsCopyDat);
      },
  },
};

//-----------------------------------------------------------------------------
// 外部接口暴露
//
export default {
  PageType,
  ControlType,
  OperateType,
  LayoutType,
  AttrType,
  PanelType,
  PageAttributeType,
  SystemPermissionsAttributeType,
  PosAttributeType,
  AttrSelType,
  AttrSelTypeDesc,
  $Set,
  interfaceContrast
};