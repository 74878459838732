import axios from '../axios'

//查询首页配置
export const getIndexConfig = () => {
    return axios({
        method: "get",
        url: `indexConfig`,
    })
}
//更新首页配置
export const putIndexConfig = (data) => {
    return axios({
        method: "put",
        url: `indexConfig`,
        data:data,
    })
}
//查询首页子配置
export const getSubIndexConfig = () => {
    return axios({
        method: "get",
        url: `subIndexConfig`,
    })
}
//更新首页子配置
export const putSubIndexConfig = (data) => {
    return axios({
        method: "put",
        url: `subIndexConfig`,
        data:data,
    })
}
// //查询看板配置
// export const getKanbanConfig= () => {
//     return axios({
//         method: "get",
//         url: `kanbanConfig`,
//     })
// }
// //更新看板配置
// export const putKanbanConfig = (data) => {
//     return axios({
//         method: "put",
//         url: `kanbanConfig`,
//         data:data,
//     })
// }
//查询告警配置
export const getAlarmConfig= () => {
    return axios({
        method: "get",
        url: `alarmConfig`,
    })
}
//更新告警配置
export const putAlarmConfig = (data) => {
    return axios({
        method: "put",
        url: `alarmConfig`,
        data:data,
    })
}
//查询数据大屏配置
export const getDataScreenConfig= () => {
    return axios({
        method: "get",
        url: `dataScreenConfig`,
    })
}
//更新数据大屏配置
export const putDataScreenConfig = (data) => {
    return axios({
        method: "put",
        url: `dataScreenConfig`,
        data:data,
    })
}
