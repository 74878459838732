// HMIDOMCtrlMG DOM控件管理
//
//  依赖
//      无
//
//  历史
//      创建    LEE     2021/07/20
//=====================================================================================
//-------------------------------------------------------------------------------------
// DOM控件管理
//
const cDOMCtrlMG = {
    //---------------------------------------------------------------------------------
    // 所有属性
    //
    // 父窗口的DOM
    parentCtrl:         null,
    // 所有临时显示用的DOM控件
    allDisplayDOMCtrls: [],
    
    domCtrls:true,
    //-----------------------------------------------------------------------------
    // 根据页面ID和控件ID 创建键值
    //
    generateDOMKey: function(vCurPgId, vCtrlId) {
        // 创建DOM Control Key
        return 100000 * vCurPgId + vCtrlId;
    },
    //-----------------------------------------------------------------------------
    // 添加临时显示用DOM控件
    //数据残存问题
    getDisplayDOMCtrls: function (vCurPgId, vCtrlId, vDOMTag, vDOMCtrl) {
        // 创建DOM Control Key
        let vDOMKey     = this.generateDOMKey(vCurPgId, vCtrlId);
        let vDOMFrame   = null;
        let vDOMValue   = null;

        // if (this.domCtrls) {
        //     this.allDisplayDOMCtrls=[];
        // }
        // 查询是否存在
        // console.log(this.allDisplayDOMCtrls);
        for(let idx = 0; idx < this.allDisplayDOMCtrls.length; idx++) {
            // 检查是否存在
           
            vDOMFrame = this.allDisplayDOMCtrls[idx];
            // 检查是否存在
            if(vDOMFrame) {
                // 检查键值是否存在
                if(vDOMKey === vDOMFrame.key) {
                    // 获取对象
                    vDOMValue = vDOMFrame.obj;
                    // 找到
                    break;
                }
            }
        }
        // 检查对象是否存在
        if (!vDOMValue) {
            // 创建对象
            
            vDOMValue = document.createElement(vDOMTag);
            // 添加外部控件属性
            vDOMValue.isOutCtrl = true;
            // 设置DOM控件的显示层次
            vDOMValue.style.zIndex = this.singleDisplayDomCtrlZIdx;
            // 重置临时显示用的DOM控件用绘制层索引
            this.singleDisplayDomCtrlZIdx++;
            // 添加到父控件

            this.parentCtrl.appendChild(vDOMValue);
            // 添加到所有临时显示用的DOM控件
            this.allDisplayDOMCtrls.push({key: vDOMKey, obj: vDOMValue});
            // 新创建的控件，需要初始化
            vDOMCtrl.new = true;
        }
        this.domCtrls=false
        // 设置控件
        vDOMCtrl.obj = vDOMValue;
    },
    //-----------------------------------------------------------------------------
    // 删除选中控件的关联DOM控件
    //
    deleteLinkedDOMCtrls: function(vCurPgId, vCtrlId) {
        // 创建DOM Control Key
        let vDOMKey     = this.generateDOMKey(vCurPgId, vCtrlId);
        let vDOMFrame   = null;
        let vDOMValue   = null;
        let vDOMCtrlIdx = -1;

        // 查询是否存在
        for(let idx = 0; idx < this.allDisplayDOMCtrls.length; idx++) {
            // 检查是否存在
            vDOMFrame = this.allDisplayDOMCtrls[idx];
            // 检查是否存在
            if(vDOMFrame) {
                // 检查键值是否存在
                if(vDOMFrame.key) {
                    // 检查键值是否存在
                    if(vDOMKey === vDOMFrame.key) {
                        // 控件索引
                        vDOMCtrlIdx = idx;
                        // 获取对象
                        vDOMValue   = vDOMFrame.obj;
                        // 找到
                        break;
                    }
                }
            }
        }
        // 检查对象是否存在
        if(vDOMValue) {
            // 从父控件中删除
            this.parentCtrl.removeChild(vDOMValue);
            // 控件索引
            if(vDOMCtrlIdx > -1) {
                // 释放
                delete (this.allDisplayDOMCtrls[vDOMCtrlIdx]);
            }
        }
    },
    //-----------------------------------------------------------------------------
    // 隐藏所有的DOM控件
    //
    hideAllDOMCtrls: function() {
        // 控件
        let lDOMCtrl = null;
        // 遍历
        for(let idx = 0; idx < this.allDisplayDOMCtrls.length; idx++) {
            // 设置控件不可见
            lDOMCtrl = this.allDisplayDOMCtrls[idx];
            // 检查是否存在
            if(lDOMCtrl) {
                // 检查DOM对象是否存在
                if(lDOMCtrl.obj) {
                    // 检查样式是否存在
                    if(lDOMCtrl.obj.style) {
                        // 隐藏
                        lDOMCtrl.obj.style.display  = 'none';
                    }
                }
            }
        }
    },
};

// 外部接口暴露
export default cDOMCtrlMG;