<template>
  <div id="app">
    <router-view   v-if="newReload"/>
  </div>
</template>

<script>
// import Login from "./view/Login.vue";

import HIMDraw from "./util/draw/HMIDraw";
export default {
  name: "App",
  provide (){
     return {
       reload:this.reload
     }
  },
  components: {},
  data() {
    return {
      newReload:true,
      zoom: 1,
      lastTime: null, // 最后一次点击的时间
      currentTime: null, // 当前点击的时间
      timeOut: 30 * 60 * 1000, // 设置超时时间:30分钟
    };
  },
  created() {
    // console.log(document.body.clientWidth);
    // *(window.screen.width/1920)*(window.innerWidth/)
    this.zoom = document.body.offsetWidth / 1920;
    HIMDraw.domZoom = this.zoom;
    let body = document.getElementsByTagName("body")[0];
    body.style.zoom = this.zoom;
    body.style.width = "1920px";
    body.style.height = "100%";
    // console.log(document.body.clientWidth);
    //  window.devicePixelRatio 浏览器缩放比率
    this.lastTime = new Date().getTime();
    //加在页面读取sessionStorage里面的数据
    if (sessionStorage.getItem("store"))
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );

    //刷新把vuex数据暂存到sessionStorage中
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
  methods: {
    reload(){
       this.newReload = false
       this.$nextTick(function(){
          this.newReload = true
       })
    }
    // isTimeOut() {
    //   this.currentTime = new Date().getTime(); // 记录这次点击的时间
    //   if (this.currentTime - this.lastTime > this.timeOut) {
    //     // 判断上次最后一次点击的时间和这次点击的时间间隔是否大于30分钟
    //     if (localStorage.getItem("loginInfo")) {
    //       // 如果是登录状态
    //       this.$router.push({ name: "login" });
    //     } else {
    //       this.lastTime = new Date().getTime();
    //     }
    //   } else {
    //     this.lastTime = new Date().getTime(); // 如果在30分钟内点击，则把这次点击的时间记录覆盖掉之前存的最后一次点击的时间
    //   }
    // },
  },
  beforeDestroy() {
    window.localStorage.setItem("permissions", "");
  },
};
</script>

<style>
.el-notification {
  background-color: rgb(254, 180, 168) !important;
  border: none;
}
* {
  box-sizing: border-box;
}
html,
body,
#app {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0;
  overflow: hidden;
  margin: 0;
}
#app {
  font-family: "PingFang SC";
  /* font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", , Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.el-message-box__btns {
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
}
.el-message-box__btns button {
  margin-left: 5px;
}
.LargeDataScreen {
  overflow: auto;
}
.LargeDataScreen div,
span {
  font-size: 13px;
}
.LargeDataScreen .el-select-dropdown {
  background-color: rgb(3, 16, 50);
  color: #ccf2ff;
}
.LargeDataScreen .el-select-dropdown__item {
  height: 27px;
  color: #ccf2ff;
}
.LargeDataScreen
  .el-select-dropdown.is-multiple
  .el-select-dropdown__item.selected::after {
  font-size: 13px !important;
  right: 19px !important;
}
.LargeDataScreen .el-select-dropdown__empty {
  color: #ccf2ff;
}
.LargeDataScreen .selected {
  background-color: transparent !important;
  color: #409eff !important;
}
.LargeDataScreen .el-select-dropdown__item:hover {
  background-color: transparent !important;
  color: #ccf2ff;
}
.LargeDataScreen .el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: transparent !important;
}
.LargeDataScreen .el-picker-panel {
  font-size: 13px !important;
  background-color: rgb(3, 16, 50);
  color: #ccf2ff !important;
  width: 320px !important;
  height: 326px !important;
}
.LargeDataScreen .el-picker-panel__content {
  width: 288px !important;
  height: 230px !important;
  margin: 0 13px;
}
.LargeDataScreen .el-picker-panel__content .el-date-table {
  width: 100%;
}

.LargeDataScreen .el-picker-panel__icon-btn,
.LargeDataScreen .el-date-picker__header-label,
.LargeDataScreen .el-date-table th {
  font-size: 13px !important;
  color: #ccf2ff !important;
}
.LargeDataScreen .el-date-table td.disabled div {
  background-color: rgb(8, 80, 132);
}
.el-dropdown-menu__item--divided::before {
  content: none;
  margin: 0 !important;
}
::v-deep .ck-balloon-panel {
  z-index: 9999 !important;
}
 .search-tips{
  z-index: 9999 !important;
}
</style>
