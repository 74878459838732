import axios from '../axios'

//系统接口新增
export const addInterface = data => {
  return axios({
    method: "post",
    url: `interface`,
    data:data,
  })
}

//删除接口
export const delInterface = data => {
  return axios({
    method: "delete",
    url: `interface/${data}`,
 
  })
}

//更新接口 
export const putInterface = () => {
  return axios({
    method: "put",
    url: `interface`,
    data:data
  })
}

//查看接口详情 
export const getInterface = data => {
  return axios({
    method: "get",
    url: `interface/${data}`,
  })
}

//查询接口分页
export const getInterfacePage = data => {
  return axios({
    method: "get",
    url: `interface/page?size=${data.size}&current=${data.current}&interfaceName=${data.interfaceName}`,
   
  })
}

//查询接口列表
export const getInterfaceList = data => {
  return axios({
    method: "get",
    url: `interface/list`,
   
  })
}

