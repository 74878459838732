import * as account from './moudules/account'
import * as auth from './moudules/auth'
import * as communicationProtocol from './moudules/communicationProtocol'
import * as contacts from './moudules/contacts'
import * as dtu from './moudules/dtu'
import * as gateway from './moudules/gateway'
import * as industry from './moudules/industry'
import * as mainPage from './moudules/mainPage'
import * as menuManagement from './moudules/menuManagement'
import * as networkMode from './moudules/networkMode'
import * as original from './moudules/originalScript'
import * as plan from './moudules/plan'
import * as platform from './moudules/platform'
import * as project from './moudules/project'
import * as projectGroup from './moudules/projectGroup'
import * as pub from './moudules/public'
import * as role from './moudules/role'
import * as server from './moudules/server'
import * as mqtt from './moudules/mqtt'
import * as extended from './moudules/extended'
import * as home from './moudules/home'
import * as warning from './moudules/warning'
import * as camera from './moudules/camera'
import * as dataApplication from './moudules/dataApplication'
import * as dataCondition from './moudules/dataCondition'
import * as baseCondition from './moudules/baseCondition'
import * as Interface from './moudules/interface'
import * as Attribute from './moudules/attribute'
import * as SetTitleConfig from './moudules/setTitleConfig'
import * as largeDataScreen from './moudules/largeDataScreen'
import * as configurationImgType from './moudules/configurationImgType'
import * as weekly from './moudules/weekly'
import * as iccid from './moudules/iccid'
import * as parameter from './moudules/parameter'
import * as alarmSolution from './moudules/alarmSolution'
import * as workOrder from './moudules/workOrder'
import * as operationLog from './moudules/operationLog'
import * as payParam from './moudules/payParam'
import * as projectTempState from './moudules/projectTempState'
export default {
    pub,
    auth,
    platform,
    menuManagement,
    role,
    account,
    industry,
    communicationProtocol,
    original,
    gateway,
    mainPage,
    plan,
    contacts,
    networkMode,
    dtu,
    projectGroup,
    project,
    server,
    mqtt,
    extended,
    home,
    warning,
    camera,
    dataCondition,
    dataApplication,
    Interface,
    Attribute,
    SetTitleConfig,
    largeDataScreen,
    baseCondition,
    configurationImgType,
    weekly,
    iccid,
    parameter,
    alarmSolution,
    workOrder,
    operationLog,
    payParam,
    projectTempState
}
