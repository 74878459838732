import axios from '../axios'

//查看序列号列表
export const getDtuPage = data => {
  return axios({
    method: "get",
    url: `/dtu/page?current=${data.current}&size=${data.size}&dtuSn=${data.dtuSn}&dtuId=${data.dtuId}&hardwareTypeId=${data.hardwareTypeId}&firmwareTypeId=${data.firmwareTypeId}&networkModeId=${data.networkModeId}&dtuState=${data.dtuState}`,
  })
}
//查看序列号列表
export const getThirdPartPage = data => {
  return axios({
    method: "get",
    url: `/dtu/thirdPart/page?current=${data.current}&size=${data.size}&dtuSn=${data.dtuSn}&thirdSn=${data.thirdSn}`,
  })
}

//硬件类型列表
export const hardwareList = () => {
  return axios({
    method: "get",
    url: `/dtu/hardwareTypes`,
  })
}

//查看序列号列表
export const dtuImport = data => {
  return axios({
    method: "post",
    url: `/dtu/import`,
    data: data,
    Contenttype:'form-data'
  })
}
//固件类型列表
export const firmwareTypes = data => {
  return axios({
    method: "get",
    url: `/dtu/${data}/firmwareTypes`,
  })
}

//联网方式
export const networkModeList = () => {
  return axios({
    method: "get",
    url: `/networkMode/list`,
  })
}

// 下发序列号
export const addDtu = data => {
  return axios({
    method: "post",
    url: `/dtu/issue`,
    data: data
  })
}

// 编辑序列号
export const editDtu = data => {
  return axios({
    method: "put",
    url: `/dtu`,
    data: data
  })
}
//获取序列号详情
export const details= data => {
  return axios({
    method: "get",
    url: `/dtu/info/${data}`,
  })
}
//序列号升级
export const upgrade= data => {
  return axios({
    method: "get",
    url: `/dtu/upgrade/${data}`,
  })
}
//序列号操作日志
export const dtuBindingLogPage= data => {
  return axios({
    method: "get",
    url: `/dtu/bindingLog/page?current=${data.current}&size=${data.size}&dtuSn=${data.dtuSn}&bindingState=${data.bindingState}`,
  })
}
//序列号上下线日志
export const dtuOnlineLog= data => {
  return axios({
    method: "get",
    url: `/dtu/online/page?current=${data.current}&size=${data.size}&dtuSn=${data.dtuSn}`,
  })
}
