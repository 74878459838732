import axios from '../axios'

//行业名称不分页
export const getIndustryList = (data = '') => {
  return axios({
    method: "get",
    url: `industry/list?industryName${data}`,
  })
}

//串口配置
export const addConfig = (data) => {
  return axios({
    method: "put",
    url: `serialPortConfig`,
    data: data
  })
}

//查询串口配置
export const getConfig = (data) => {
  return axios({
    method: "get",
    url: `serialPortConfig/${data}`,
    data: data
  })
}

//设备列表分页 
export const getOrPage = (data) => {
  return axios({
    method: "get",
    url: `original/page?isPublic=${data.isPublic}&industryId=${data.industryId}&current=${data.current}&size=${data.size}`,
  })
}

//设备列表不分页
export const getOriginalList = data => {
  return axios({
    method: "get",
    url: `original/list?isPublic=${data.isPublic}&industryId=${data.industryId}`,
  })
}

//设备详情
export const getOriginal = (data) => {
  return axios({
    method: "get",
    url: `original/${data}`,
  })
}

//创建设备----私有
export const addPrivateOr = (data) => {
  return axios({
    method: "post",
    url: `original`,
    data: data,
  })
}

//创建设备----公有
export const addPublicOr = (data) => {
  return axios({
    method: "post",
    url: `original/public`,
    data: data,
  })
}

//删除设备 ----私有
export const delPrivateOr = (data) => {
  return axios({
    method: "delete",
    url: `original/${data}`,
  })
}

//删除设备 ----公有
export const delPublicOr = (data) => {
  return axios({
    method: "delete",
    url: `original/public/${data}`,
  })
}

//编辑设备 ----私有
export const editPrivateOr = (data) => {
  return axios({
    method: "put",
    url: `original`,
    data: data,
  })
}

//编辑设备 ----公有
export const editPublicOr = (data) => {
  return axios({
    method: "put",
    url: `original/public`,
    data: data,
  })
}

// //获取参数类型列表 
// export const getParamType = () => {
//   return axios({
//     method: "get",
//     url: `parameter/types`,
//   })
// }

// //参数列表分页
// export const getParamPage = (data) => {
//   return axios({
//     method: "get",
//     url: `parameter/page?size=${data.size}&current=${data.current}&originalId=${data.originalId}`,
//   })
// }

// //参数布尔列表不分页
// export const getParamBoolList = (data) => {
//   return axios({
//     method: "get",
//     url: `parameter/${data}/boolParams`,
//   })
// }
// //参数数值列表不分页
// export const getParamStrList = (data) => {
//   return axios({
//     method: "get",
//     url: `parameter/${data}/notBoolParams`,
//   })
// }
// //参数非布尔参数列表不分页
// export const getParamList = (data) => {
//   return axios({
//     method: "get",
//     url: `parameter/${data}/params`,
//   })
// }
// //除数据应用所有参数列表不分页
// export const getSubList = (data) => {
//   return axios({
//     method: "get",
//     url: `parameter/subList?subOriginalId=${data}`,
//   })
// }

// // 参数详情
// export const paramDetail = (data) => {
//   return axios({
//     method: "get",
//     url: `parameter/${data}`,
//   })
// }

// // 新建公共参数
// export const createPublicParam = (data) => {
//   return axios({
//     method: "post",
//     url: `parameter/public`,
//     data: data
//   })
// }

// // 新建私有参数
// export const createParam = (data) => {
//   return axios({
//     method: "post",
//     url: `parameter`,
//     data: data
//   })
// }

// // 编辑公共参数
// export const editPublicParam = (data) => {
//   return axios({
//     method: "put",
//     url: `parameter/public`,
//     data: data
//   })
// }

// // 编辑私有参数
// export const editParam = (data) => {
//   return axios({
//     method: "put",
//     url: `parameter`,
//     data: data
//   })
// }

// // 删除公共参数
// export const delPublicParam = (data) => {
//   return axios({
//     method: "delete",
//     url: `parameter/public/${data}`,
//   })
// }

// // 删除私有参数
// export const delParam = (data) => {
//   return axios({
//     method: "delete",
//     url: `parameter/${data}`,
//   })
// }

// 查看图片
export const checkPic = (data) => {
  return axios({
    method: "get",
    url: `original/img/${data}`,
  })
}

//删除图片
export const deletePic = (data) => {
  return axios({
    method: "delete",
    url: `original/img?imgName=${data}`,
  })
}

//上传图片
export const uploadPic = (data) => {
  return axios({
    method: "post",
    url: `original/img`,
    data:data
  })
}

//上传图片
export const addPic = (data) => {
  return axios({
    method: "post",
    url: `original/img`,
    data:data
  })
}
//导入参数
export const putParameter = (data) => {
  return axios({
    method: "post",
    url: `/parameter/import`,
    data:data
  })
}
//导出参数
export const getParameter = (data) => {
  return axios({
    method: "get",
    url: `/parameter/${data}/export`,
  })
}

