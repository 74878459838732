import axios from '../axios'

//新增支付方案
export const addPayParam = (data) => {
    return axios({
      method: "post",
      url: `payParam`,
      data:data
    })
  }
//查询支付方案
export const getPayParam = (data) => {
    return axios({
      method: "get",
      url: `payParam/wechatPay/${data.payParamId}?dtuSn=${data.dtuSn}`,
    })
  }
//修改支付方案
export const editPayParam = (data) => {
    return axios({
      method: "put",
      url: `payParam`,
      data:data,
    })
  }
//删除支付方案
export const delPayParam = (data) => {
    return axios({
      method: "delete",
      url: `payParam/${data}`,
    })
  }
//查询支付列表
export const pagePayParam = (data) => {
    return axios({
      method: "get",
      url: `payParam/page`,
      data:data
    })
  }
//支付方案下设备列表
export const PayParamList = (data) => {
    return axios({
      method: "get",
      url: `payParam/pro/${data.planId}/${data.current}/${data.size}`,
    })
  }
//支付方案下上传背景图
export const wechatimg = (data) => {
    return axios({
      method: "get",
      url: `img/wechatimg`,
      data:data,
    })
  }
