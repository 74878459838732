import axios from '../axios'

//项目列表分页
export const getProjectPage = data => {
  return axios({
    method: "get",
    url: `project/page?size=${data.size}&current=${data.current}&projectGroupId=${data.projectGroupId}&projectName=${data.projectName}`,
  })
}
//项目列表
export const getProjectList = (data) => {
  return axios({
    method: "get",
    url: `/project/list?projectName=${data.projectName}`,
  })
}

//新增项目
export const addProject = data => {
  return axios({
    method: "post",
    url: `project`,
    data: data
  })
}
//编辑项目
export const editProject = data => {
  return axios({
    method: "put",
    url: `project`,
    data: data
  })
}
//删除项目
export const delProject = data => {
  return axios({
    method: "delete",
    url: `project/${data}`,
  })
}
//项目详情
export const getProject = data => {
  return axios({
    method: "get",
    url: `project/${data}`
  })
}

//项目下发
export const sendData = data => {
  return axios({
    method: "post",
    url: `project/cmds`,
    data:data
  })
}
//历史数据下载
export const downloadData = data => {
  return axios({
    method: "post",
    url: `/project/download`,
    data:data
  })
}
//信号历史数据
export const getCsqHistoryData = data => {
  return axios({
    method: "get",
    url: `/project/csqHistoryData?projectId=${data.projectId}&dtuId=${data.dtuId}&date=${data.date}`,
   
  })
}
//更新配置
export const updateConfig = data => {
  return axios({
    method: "put",
    url: `/project/updateConfig`,
    data:data,
  })
}
//获取项目触发器列表
export const getAlarmPage= data => {
  return axios({
    method: "get",
    url: `/alarm/propage?planId=${data.planId}&size=${data.size}&current=${data.current}&projectId=${data.projectId}`,
  })
}
//获取项目触发器列表
export const proAlarm= data => {
  return axios({
    method: "put",
    url: `/alarm/proAlarm`,
    data:data,
  })
}
//获取项目触发器列表
export const getUserPage= data => {
  return axios({
    method: "get",
    url: `/project/userPage?size=${data.size}&current=${data.current}&projectState=${data.projectState}&planId=${data.planId}&projectName=${data.projectName}`,
  })
}

//获取项目地址
export const getAddress = data => {
  return axios({
    method: 'get',
    url:`/dtu/location/${data}`
  })
}

