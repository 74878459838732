// HMIOperateCtrlMG 操作控制管理js
//
//  依赖
//      无
//
//  历史
//      创建    LEE     2021/05/08
//=============================================================================
import HP from './HMIProject.js'
//-----------------------------------------------------------------------------
// 页面操作常量定义
//
const OperateCtrlConst = {
  // 最大动作数量
  maxActionCount:   100,
  // 操作动作
  actions: {
    atErr:          0,
    atCreate:       1,
    atModify:       2,
    atDelete:       3,
    atCut:          4,
    atPaste:        5,
  },
}
//-----------------------------------------------------------------------------
// 页面操作动作
//
class PageOperateAction {
  //---------------------------------------------------------------------------
  // 构造函数
  // 
  constructor() {
    // 操作动作
    this.action   = OperateCtrlConst.actions.atErr;
    // 操作的控件
    this.controls = [];
  }
  //---------------------------------------------------------------------------
  // 设置操作动作
  //
  setAction(action, ctrls) {
    // 检查action是否有效
    if(action <= OperateCtrlConst.actions.atErr)    return;
    if(action >  OperateCtrlConst.actions.atPaste)  return;
    
    // 设置
    this.action = action;
    // 清空
    this.controls = [];
    // 重新添加控件
    for(let idx = 0; idx < ctrls.length; idx++) {
      // 添加控件
      this.controls.push(JSON.parse(JSON.stringify(ctrls[idx])));
    }
  }
  //---------------------------------------------------------------------------
  // 执行当前的操作
  //
  execute() {
    // 检查动作类型
    switch(this.action) {
      case OperateCtrlConst.actions.atCreate:
        // 删除已经创建的控件
        HP.vHMIPrjFunc.deleteCtrlFromOperates(this.controls);
        break;
      case OperateCtrlConst.actions.atModify:
        // 执行修改控件属性
        HP.vHMIPrjFunc.modifyCtrlFromOperates(this.controls);
        break;
      case OperateCtrlConst.actions.atDelete:
        // 重新添加已经删除的控件
        HP.vHMIPrjFunc.addCtrlFromOperates(this.controls);
        break;
      case OperateCtrlConst.actions.atCut:
        // 重新添加已经剪切的控件
        HP.vHMIPrjFunc.addCtrlFromOperates(this.controls);
        break;
      case OperateCtrlConst.actions.atPaste:
        // 删除粘贴的控件
        HP.vHMIPrjFunc.deleteCtrlFromOperates(this.controls);
        break;
    }
  }
  //---------------------------------------------------------------------------
  // 执行当前的操作重做
  //
  executeRedo() {
    // 检查动作类型
    switch(this.action) {
      case OperateCtrlConst.actions.atCreate:
        // 再次创建的控件
        HP.vHMIPrjFunc.addCtrlFromOperates(this.controls);
        break;
      case OperateCtrlConst.actions.atModify:
        // 执行修改控件属性
        HP.vHMIPrjFunc.modifyCtrlFromOperates(this.controls);
        break;
      case OperateCtrlConst.actions.atDelete:
        // 再次删除的控件
        HP.vHMIPrjFunc.deleteCtrlFromOperates(this.controls);
        break;
      case OperateCtrlConst.actions.atCut:
        // 再次剪切的控件
        HP.vHMIPrjFunc.cutCtrlFromOperates(this.controls);
        break;
      case OperateCtrlConst.actions.atPaste:
        // 再次粘贴的控件
        HP.vHMIPrjFunc.addCtrlFromOperates(this.controls);
        break;
    }
  }
}
//-----------------------------------------------------------------------------
// 页面操作控制
//
class PageOperateCtrl {
  //---------------------------------------------------------------------------
  // 构造函数
  // 
  constructor(vPageId) {
    // 页面操作属性
    this.pageId           = vPageId;
    // 页面撤销动作
    this.allUndoActions   = [];
    // 页面重做动作
    this.allRedoActions   = [];
  }
  //---------------------------------------------------------------------------
  // 添加撤销动作
  //
  addUndo(action, ctrlsArray) {
    // 页面操作动作
    var vAction = new PageOperateAction;
    // 设置
    vAction.setAction(action, ctrlsArray);
    // 添加到撤销动作
    this.allUndoActions.push(vAction);
    // 检查长度
    if(this.allUndoActions.length > OperateCtrlConst.maxActionCount) {
      // 删除多余动作
      this.allUndoActions.splice(0, 1);
    }
    // 释放重做动作
    this.allRedoActions   = [];
  }
  //---------------------------------------------------------------------------
  // 取消上次的撤销动作
  //
  cancelLastUndo() {
    // 取消上次的撤销动作
    this.allUndoActions.pop();
  }
  //---------------------------------------------------------------------------
  // 内部使用直接添加撤销动作
  //
  addUndoInside(actionArray) {
    // 页面操作动作
    var vAction = null;
    // 检查动作数组长度
    if(actionArray.length > 0) {
      // 设置
      vAction = actionArray[0];
      // 添加到撤销动作
      this.allUndoActions.push(vAction);
      // 检查长度
      if(this.allUndoActions.length > OperateCtrlConst.maxActionCount) {
        // 删除多余动作
        this.allUndoActions.splice(0, 1);
      }
    }
  }
  //---------------------------------------------------------------------------
  // 执行撤销动作
  //
  exeUndo() {
    // 获取动作数组
    let actionsArray = null;
    // 检查长度
    if(this.allUndoActions.length > 0) {
      // 删除撤销的动作
      actionsArray = this.allUndoActions.splice(this.allUndoActions.length-1, 1);
      //
      // 执行详细
      //
      this.executeActions(actionsArray);
      // 添加到重做列表
      this.addRedoInside(actionsArray);
    }
  }
  //---------------------------------------------------------------------------
  // 获取撤销动作数量
  //
  getUndoCnt() {
    // 获取撤销动作数量
    return this.allUndoActions.length;
  }
  //---------------------------------------------------------------------------
  // 添加重做动作
  //
  addRedo(action, ctrlsArray) {
    // 页面操作动作
    var vAction = new PageOperateAction;
    // 设置
    vAction.setAction(action, ctrlsArray);
    // 添加到重做动作
    this.allRedoActions.push(vAction);
    // 检查长度
    if(this.allRedoActions.length > OperateCtrlConst.maxActionCount) {
      // 删除多余动作
      this.allRedoActions.splice(0, 1);
    }
  }
  //---------------------------------------------------------------------------
  // 内部使用直接添加重做动作
  //
  addRedoInside(actionArray) {
    // 页面操作动作
    var vAction = null;
    // 检查动作数组长度
    if(actionArray.length > 0) {
      // 设置
      vAction = actionArray[0];
      // 添加到重做动作
      this.allRedoActions.push(vAction);
      // 检查长度
      if(this.allRedoActions.length > OperateCtrlConst.maxActionCount) {
        // 删除多余动作
        this.allRedoActions.splice(0, 1);
      }
    }
  }
  //---------------------------------------------------------------------------
  // 执行重做动作
  //
  exeRedo() {
    // 获取动作数组
    let actionsArray = null;
    // 检查长度
    if(this.allRedoActions.length > 0) {
      // 删除撤销的动作
      actionsArray = this.allRedoActions.splice(this.allRedoActions.length-1, 1);
      //
      // 执行详细
      //
      this.executeActions(actionsArray, true);
      // 添加到撤销列表
      this.addUndoInside(actionsArray);
    }
  }
  //---------------------------------------------------------------------------
  // 获取重做动作数量
  //
  getRedoCnt() {
    // 获取撤销动作数量
    return this.allRedoActions.length;
  }
  //---------------------------------------------------------------------------
  // 执行撤销与重做的动作
  //
  executeActions(actionsArray, redoMode=false) {
    // 检查是否为重做
    if(!redoMode) {
      // 检查动作数组
      for(let idx = 0; idx < actionsArray.length; idx++) {
        // 执行每一个操作
        actionsArray[idx].execute();
      }
    } else {
      // 检查动作数组
      for(let idx = 0; idx < actionsArray.length; idx++) {
        // 执行每一个操作重做
        actionsArray[idx].executeRedo();
      }
    }
  }
}
//-----------------------------------------------------------------------------
// 操作控制管理
//
const gOperateCtrlMG = {
    // 各个页面的操作
    allPagesOperateCtrls: [],
    // 当前的页面Id
    currentPageId:        -1,
    // 当前页面的管理控制
    currentPageCtrl:      null,
    
    //-------------------------------------------------------------------------
    // 搜索页面操作管理
    //
    searchPageOperateCtrl: function(vPageId) {
      // 页面操作管理
      var vPageOperateCtrl = null;
      // 检查是否为空
      if(this.currentPageCtrl != null) {
        // 检查页面Id是否一致
        if(vPageId == this.currentPageId) {
          // 获取页面操作管理
          vPageOperateCtrl = this.currentPageCtrl;
        }
      }
      // 检查是否需要重新搜索
      if(null == vPageOperateCtrl) {
        // 重新搜索
        for(let idx = 0; idx < this.allPagesOperateCtrls.length; idx++) {
          // 检查是否一致
          if(vPageId == this.allPagesOperateCtrls[idx].pageId) {
            // 设置当前的页面Id
            this.currentPageId    = vPageId;
            // 设置页面操作管理
            this.currentPageCtrl  = this.allPagesOperateCtrls[idx];
            // 获取页面操作管理
            vPageOperateCtrl      = this.currentPageCtrl;
            // 找到时退出
            break;
          }
        }
      }
      // 检查是否需要新增到列表
      if(null == vPageOperateCtrl) {
        // 页面操作管理
        vPageOperateCtrl      = new PageOperateCtrl(vPageId);
        // 设置当前的页面Id
        this.currentPageId    = vPageId;
        // 设置页面操作管理
        this.currentPageCtrl  = vPageOperateCtrl;
        // 创建并添加到数组
        this.allPagesOperateCtrls.push(vPageOperateCtrl);
      }
      // 返回操作管理
      return vPageOperateCtrl;
    },
    //-------------------------------------------------------------------------
    // 检查当前页面是否可以执行撤销操作
    //
    checkPageCanUndo: function(vPageId) {
      // 搜索页面操作管理
      let vOpCtrl = this.searchPageOperateCtrl(vPageId);
      // 检查页面操作管理
      if(vOpCtrl) {
        // 获取撤销长度
        return vOpCtrl.getUndoCnt() > 0;
      }
      // 不能执行撤销操作
      return false;
    },
    //-------------------------------------------------------------------------
    // 检查当前页面是否可以执行重做操作
    //
    checkPageCanRedo: function(vPageId) {
      // 搜索页面操作管理
      let vOpCtrl = this.searchPageOperateCtrl(vPageId);
      // 检查页面操作管理
      if(vOpCtrl) {
        // 获取重做长度
        return vOpCtrl.getRedoCnt() > 0;
      }
      // 不能执行重做操作
      return false;
    },
    //-------------------------------------------------------------------------
    // 添加撤销操作
    //
    addUndoOperates: function(vPageId, vAction, vControls) {
      // 搜索页面操作管理
      let vOpCtrl = this.searchPageOperateCtrl(vPageId);
      // 检查页面操作管理
      if(vOpCtrl) {
        // 添加撤销操作
        vOpCtrl.addUndo(vAction, vControls);
      }
    },
    //-------------------------------------------------------------------------
    // 添加剪切撤销操作
    //
    addUndoCutCtrls: function(vPageId, vCtrls) {
      // 添加撤销操作
      this.addUndoOperates(vPageId, OperateCtrlConst.actions.atCut, vCtrls);
    },
    //-------------------------------------------------------------------------
    // 添加删除撤销操作
    //
    addUndoDeleteCtrls: function(vPageId, vCtrls) {
      // 添加撤销操作
      this.addUndoOperates(vPageId, OperateCtrlConst.actions.atDelete, vCtrls);
    },
    //-------------------------------------------------------------------------
    // 添加创建控件到操作中
    //
    addUndoCreatedCtrls: function(vPageId, vCtrls) {
      // 添加撤销操作
      this.addUndoOperates(vPageId, OperateCtrlConst.actions.atCreate, vCtrls);
    },
    //-------------------------------------------------------------------------
    // 添加复制控件到操作中
    //
    addUndoPasteCtrls: function(vPageId, vCtrls) {
      // 添加撤销操作
      this.addUndoOperates(vPageId, OperateCtrlConst.actions.atPaste, vCtrls);
    },
    //-------------------------------------------------------------------------
    // 添加修改控件到操作中
    //
    addUndoModifyCtrls: function(vPageId, vCtrls) {
      // 添加撤销操作
      this.addUndoOperates(vPageId, OperateCtrlConst.actions.atModify, vCtrls);
    },
    //-------------------------------------------------------------------------
    // 取消上次的修改控件到操作中
    //
    cancelLastUndo: function(vPageId) {
      // 搜索页面操作管理
      let vOpCtrl = this.searchPageOperateCtrl(vPageId);
      // 检查页面操作管理
      if(vOpCtrl) {
        // 取消上次的撤销操作
        vOpCtrl.cancelLastUndo();
      }
    },
    //-------------------------------------------------------------------------
    // 执行撤销操作
    //
    exeUndo: function(vPageId) {
      // 搜索页面操作管理
      let vOpCtrl = this.searchPageOperateCtrl(vPageId);
      // 检查页面操作管理
      if(vOpCtrl) {
        // 执行撤销
        vOpCtrl.exeUndo();
      }
    },
    //-------------------------------------------------------------------------
    // 执行重做操作
    //
    exeRedo: function(vPageId) {
      // 搜索页面操作管理
      let vOpCtrl = this.searchPageOperateCtrl(vPageId);
      // 检查页面操作管理
      if(vOpCtrl) {
        // 执行重做
        vOpCtrl.exeRedo();
      }
    },
}
// 外部接口暴露
export default gOperateCtrlMG;