// HMIZoombar 缩放工具栏js
//
//  依赖
//      HMIGlobal.js
//
//  历史
//      创建    LEE     2021/04/06
//=============================================================================
import HG from './HMIGlobal.js'
import gDrawHandle from './HMIDraw.js'
//-----------------------------------------------------------------------------
// 缩放操作实例
//
var gZoomHandle = {
    //
    // 属性
    //
    // 父窗口
    parent:             null,
    // canvas
    canvas:             null,
    ctx:                null,
    // canvas 位置
    rectCanvasArea:     { l: 0, t: 0, w: 0, h: 0 },

    // canvas normal
    canvasNormal:       null,
    ctxNormal:          null,
    // canvas grey
    canvasGrey:         null,
    ctxGrey:            null,
    // 初始化是否成功
    initSuccess:        false,
    // 当前缩放 默认:100%
    zoomData:           1,
    // 背景色
    backGroundColor:    '#FFFFFF00',
    // 图片显示区域
    imgArea:            { l:0, t:0, w:0, h:0 },
    // 背景图片
    imgNormal:          null,
    imgGrey:            null,
    // 标签显示区域
    lblArea:            { l:0, t:0, w:0, h:0 },
    // 鼠标点击有效区域
    mouseValidArea:     { l:0, r:0, t:0, b:0 },
    // 图片加载完成数量
    imgLoadCnt:         0,
    // 绘制缩放栏
    drawZoombarHandle:  null,
    // 设置
    settings: {
        // 初始尺寸
        width:          0,
        height:         0,
        // 设备缩放率
        deviceZoom:     1,
    },

    //-----------------------------------------------------------------------------
    // 函数
    //
    //-----------------------------------------------------------------------------
    // 初始化
    //
    init: function (elName, drawZoombar) {
        let vInitOK = false;
        // 获取 父窗口
        this.parent = document.getElementById(elName);
        // 检查是否存在
        if (this.parent) {
            // 添加canvas
            this.canvasNormal           = document.createElement('canvas');
            this.canvasGrey             = document.createElement('canvas');
            // 获取context
            this.ctxNormal              = this.canvasNormal.getContext('2d');
            this.ctxGrey                = this.canvasGrey.getContext('2d');
            // 当前设备缩放率
            this.settings.deviceZoom    = this.calcDeviceZoom(this.ctxNormal);
            // 计算最大尺寸
            this.settings.width         = this.parent.clientWidth     * this.settings.deviceZoom;
            this.settings.height        = this.parent.clientHeight    * this.settings.deviceZoom;
            // 正常
            this.canvasNormal.width     = this.settings.width;
            this.canvasNormal.height    = this.settings.height;
            // 灰色
            this.canvasGrey.width       = this.settings.width;
            this.canvasGrey.height      = this.settings.height;

            // 添加canvas
            this.canvas                 = document.createElement('canvas');
            // ID
            this.canvas.id              = HG.$Set.allCanvasID.zoomBar;
            // 显示尺寸
            this.canvas.width           = this.settings.width;
            this.canvas.height          = this.settings.height;
            // 检查缩放
            if(this.settings.deviceZoom != 1) {
                // 样式显示
                this.canvas.style.width = (this.settings.width / this.settings.deviceZoom) + 'px';
                this.canvas.style.height= (this.settings.height / this.settings.deviceZoom) + 'px';
            }
            // 鼠标样式
            this.canvas.style.cursor    = 'pointer';
            // 获取context
            this.ctx                    = this.canvas.getContext('2d');
            
            // 添加到对象中
            this.parent.appendChild(this.canvas);
            // 计算绘制属性
            this.calcDrawAttributes();
            
            // 初始化成功
            vInitOK = true;
        }
        // 检查是否为IE浏览器
        if(window.navigator.userAgent.indexOf("Trident") >= 1) {
            // 背景色
            this.backGroundColor = '#DFDFDF';
        }
        // 绘制缩放栏
        this.drawZoombarHandle = drawZoombar;

        // 初始化标志
        this.initSuccess = false;
       
        // 初始化是否成功
        return vInitOK;
    },
    //-----------------------------------------------------------------------------
    // 计算设备缩放率
    //
    calcDeviceZoom: function (context) {
        // 设备缩放率
        var backingStore = context.backingStorePixelRatio       ||
                           context.webkitBackingStorePixelRatio ||
                           context.mozBackingStorePixelRatio    ||
                           context.msBackingStorePixelRatio     ||
                           context.oBackingStorePixelRatio      || 1;
        return (window.devicePixelRatio || 1) / backingStore;
    },
    //-----------------------------------------------------------------------------
    // 图片加载
    //
    imageLoad: function() {
        // 图片加载完成数量
        gZoomHandle.imgLoadCnt++;
        // 检查加载完成数量
        if(gZoomHandle.imgLoadCnt >= 2) {
            // 加载完成后，绘制缩放栏
            if(gZoomHandle.drawZoombarHandle) {
                // 执行
                gZoomHandle.drawZoombarHandle();
            }
        }
    },
    //-----------------------------------------------------------------------------
    // 计算绘制属性
    //
    calcDrawAttributes: function() {
        // 缩放率
        let lZoomXRate      = HG.$Set.zoomBarImgs.size.cx / this.settings.width;
        // 背景图片
        this.imgGrey        = new Image;
        this.imgNormal      = new Image;
        // 设置加载函数
        this.imgGrey.addEventListener('load',   this.imageLoad,    false);
        this.imgNormal.addEventListener('load', this.imageLoad,    false);
        // 加载图片
        this.imgGrey.src    = HG.$Set.zoomBarImgs.grey;
        this.imgNormal.src  = HG.$Set.zoomBarImgs.normal;
        
        // 显示区域
        this.imgArea.l = 0;
        this.imgArea.w = this.settings.width;
        // 计算实际高度
        this.imgArea.h = HG.$Set.zoomBarImgs.size.cy / lZoomXRate;
        this.imgArea.t = (this.settings.height - this.imgArea.h) / 2;

        // 标签显示区域
        this.lblArea.l = this.imgArea.l;
        this.lblArea.t = this.imgArea.t + this.imgArea.h;
        this.lblArea.w = this.imgArea.w;
        this.lblArea.h = this.settings.height -this.lblArea.t;

        // 鼠标点击有效区域
        this.mouseValidArea.l = this.imgArea.l / this.settings.deviceZoom;
        this.mouseValidArea.r = (this.imgArea.l + this.imgArea.w) / this.settings.deviceZoom;
        this.mouseValidArea.t = (this.imgArea.t / this.settings.deviceZoom) - 5;
        this.mouseValidArea.b = (this.imgArea.t + this.imgArea.h) / this.settings.deviceZoom + 5;
    },
    //-----------------------------------------------------------------------------
    // 绘制
    //
    draw: function () {
        // 初始化是否成功
        if(!this.initSuccess) {
            // 绘制背景
            this.drawBackground();
            // 初始化成功
            this.initSuccess = true;
        }
        // 绘制进度条
        this.drawProcess();
    },
    //-----------------------------------------------------------------------------
    // 绘制背景
    //
    drawBackground: function () {
        // 背景颜色
        this.ctxNormal.fillStyle = this.backGroundColor;
        // 清空全部画面区域
        this.ctxNormal.fillRect(0, 0, this.settings.width, this.settings.height);
        // 绘制图片
        this.ctxNormal.drawImage(this.imgNormal, 
                                 this.imgArea.l, this.imgArea.t, 
                                 this.imgArea.w, this.imgArea.h);
        // 绘制文字标签
        this.drawLabel(this.ctxNormal);
        // 绘制刻度标签
        this.drawScale(this.ctxNormal);

        // 背景颜色
        this.ctxGrey.fillStyle = this.backGroundColor;
        // 清空全部画面区域
        this.ctxGrey.fillRect(0, 0, this.settings.width, this.settings.height);
        // 绘制图片
        this.ctxGrey.drawImage(this.imgGrey, 
                                this.imgArea.l, this.imgArea.t, 
                                this.imgArea.w, this.imgArea.h);
        // 绘制文字标签
        this.drawLabel(this.ctxGrey);
        // 绘制刻度标签
        this.drawScale(this.ctxGrey);
    },
    //-----------------------------------------------------------------------------
    // 绘制文字标签
    //
    drawLabel: function (ctx) {
        // 颜色字体
        ctx.fillStyle       = '#000';
        ctx.font            = (9 * this.settings.deviceZoom) + 'pt 黑体';
        ctx.textAlign       = 'left';
        ctx.textBaseline    = 'top';
        // 阴影模糊
        ctx.shadowColor     = "white";
        ctx.shadowBlur      = 2; 
        // 绘制
        ctx.fillText('25%', this.lblArea.l, this.lblArea.t);

        // 中间
        ctx.textAlign       = 'center';
        // 绘制
        ctx.fillText('100%', this.lblArea.l + this.lblArea.w / 2, this.lblArea.t);

        // 中间
        ctx.textAlign       = 'right';
        // 绘制
        ctx.fillText('300%', this.lblArea.l + this.lblArea.w, this.lblArea.t);
    },
    //-----------------------------------------------------------------------------
    // 绘制刻度标签
    //
    drawScale: function (ctx) {
        let vScaleIdx       = 0;
        let vScaleCntF      = 3;
        let vScaleCntS      = 4;
        let vHalfW          = this.lblArea.w / 2;
        let vScaleFirstW    = vHalfW / vScaleCntF;
        let vScaleSecondW   = vHalfW / vScaleCntS;
        
        // 颜色
        ctx.strokeStyle     = '#000';
        ctx.lineWidth       = 1;
        // 绘制直线
        ctx.beginPath();
        // 绘制刻度线
        for(vScaleIdx = 1; vScaleIdx < vScaleCntF; vScaleIdx++) {
            // 绘制刻度线
            ctx.moveTo(this.lblArea.l + (vScaleIdx * vScaleFirstW), this.imgArea.t);
            ctx.lineTo(this.lblArea.l + (vScaleIdx * vScaleFirstW), this.imgArea.t - 3);
        }
        // 绘制刻度线
        for(vScaleIdx = 0; vScaleIdx < vScaleCntS; vScaleIdx++) {
            // 绘制刻度线
            ctx.moveTo(this.lblArea.l + Math.round(vHalfW + (vScaleIdx * vScaleSecondW)), this.imgArea.t);
            ctx.lineTo(this.lblArea.l + Math.round(vHalfW + (vScaleIdx * vScaleSecondW)), this.imgArea.t - 3);
        }
        // 绘制
        ctx.stroke();
        ctx.closePath();
    },
    //-----------------------------------------------------------------------------
    // 计算缩放比率
    //
    calcProcessRate: function () {
        // 计算比率
        let vZoomRate = 0.5;

        // 检查缩放比率
        if(this.zoomData === 0.25) {
            // 25%
            vZoomRate = 0;
        } 
        // 检查缩放比率
        else if(this.zoomData === 0.5) {
            // 50%
            vZoomRate = 0.166667;
        }
        // 检查缩放比率
        else if(this.zoomData === 0.75) {
            // 75%
            vZoomRate = 0.333333;
        }
        // 检查缩放比率
        else if(this.zoomData === 1.5) {
            // 150%
            vZoomRate = 0.5 + 0.125;
        }
        // 检查缩放比率
        else if(this.zoomData === 2) {
            // 200%
            vZoomRate = 0.5 + 0.25;
        }
        // 检查缩放比率
        else if(this.zoomData === 2.5) {
            // 250%
            vZoomRate = 0.5 + 0.375;
        }
        // 检查缩放比率
        else if(this.zoomData === 3) {
            // 300%
            vZoomRate = 1;
        }
        else {
            // 默认值100%
            vZoomRate = 0.5;
        }

        // 缩放率
        return vZoomRate;
    },
    //-----------------------------------------------------------------------------
    // 绘制进度条
    //
    drawProcess: function () {
        // 计算比率
        let vZoomRate = this.calcProcessRate();
        // 检查比率
        if(vZoomRate < 0.99) {
            // 绘制缓存
            this.ctx.drawImage(this.canvasGrey,
                               0, 0, this.settings.width, this.settings.height,
                               0, 0, this.settings.width, this.settings.height);
        }
        // 检查比率
        if(vZoomRate > 0.01) {
            // 绘制缓存
            this.ctx.drawImage(this.canvasNormal,
                               0, 0, this.settings.width * vZoomRate, this.settings.height,
                               0, 0, this.settings.width * vZoomRate, this.settings.height);
        }
    },
    
    //-----------------------------------------------------------------------------
    // 点击缩放位置
    //
    clickZoomArea: function (vX, vY) {
        let vWidthHalf   = this.imgArea.w / (2 * this.settings.deviceZoom);
        let vScaleCntF   = 3;
        let vScaleCntS   = 4;
        let vScaleWidthF = vWidthHalf / vScaleCntF;
        let vScaleWHalfF = vScaleWidthF / 2;
        let vScaleWidthS = vWidthHalf / vScaleCntS;
        let vScaleWHalfS = vScaleWidthS / 2;

        // 计算偏移量
        this.rectCanvasArea.l = this.canvas.offsetLeft + this.parent.offsetLeft;
        this.rectCanvasArea.t = this.canvas.offsetTop + this.parent.offsetTop;
        // X 位置
        vX -= this.rectCanvasArea.l;
        // Y 位置
        vY -= this.rectCanvasArea.t;

        // 检查Y位置
        if((vY >= this.mouseValidArea.t) && (vY <= this.mouseValidArea.b)) {
            // 前半段
            if(vX <= vWidthHalf) {
                //
                // 计算前半段
                //
                // 检查刻度
                if(vX <= vScaleWHalfF) {
                    // 25%
                    this.zoomData = 0.25;
                }
                else if(vX <= vScaleWHalfF + vScaleWidthF) {
                    // 50%
                    this.zoomData = 0.5;
                }
                else if(vX <= vScaleWHalfF + vScaleWidthF * 2) {
                    // 75%
                    this.zoomData = 0.75;
                }
                else {
                    // 100%
                    this.zoomData = 1;
                }
            }
            else {
                //
                // 计算后半段
                //
                vX -= vWidthHalf;
                // 检查刻度
                if(vX <= vScaleWHalfS) {
                    // 100%
                    this.zoomData = 1;
                }
                else if(vX <= vScaleWHalfS + vScaleWidthS) {
                    // 150%
                    this.zoomData = 1.5;
                }
                else if(vX <= vScaleWHalfS + vScaleWidthS * 2) {
                    // 200%
                    this.zoomData = 2;
                }
                else if(vX <= vScaleWHalfS + vScaleWidthS * 3) {
                    // 250%
                    this.zoomData = 2.5;
                }
                else {
                    // 300%
                    this.zoomData = 3;
                }
            }

            // 设置缩放率
            gDrawHandle.setDisplayZoom(this.zoomData);
            // 刷新绘制
            this.draw();
        }
    },
    setZoomData:function(){
        this.zoomData=0.75
        // 设置缩放率
        gDrawHandle.setDisplayZoom(this.zoomData);
        // 刷新绘制
        this.draw();
    }
};

// 外部接口暴露
export default gZoomHandle;