import axios from '../axios'

//添加公共方案
export const addPublicPlan = data => {
  return axios({
    method: "post",
    url: `/plan/public`,
    data: data
  })
}
//添加私有方案
export const addPlan = data => {
  return axios({
    method: "post",
    url: `/plan`,
    data: data
  })
}
//删除公共方案
export const delPublicPlan = data => {
  return axios({
    method: "delete",
    url: `/plan/public/${data}`,
  })
}
//删除私有方案
export const delPlan = data => {
  return axios({
    method: "delete",
    url: `/plan/${data}`,
  })
}
//修改公共方案
export const editPublicPlan = data => {
  return axios({
    method: "put",
    url: `/plan/public`,
    data: data
  })
}
//修改私有方案
export const editPlan = data => {
  return axios({
    method: "put",
    url: `/plan`,
    data: data
  })
}
//复制方案
export const copyPlan = data => {
  return axios({
    method: "post",
    url: `/plan/copy`,
    data: data
  })
}

//获取方案
export const customPlan = data => {
  return axios({
    method: "get",
    url: `/plan/${data}/custom`,
  })
}

//查询方案
export const getPlan = data => {
  return axios({
    method: "get",
    url: `/plan/${data}`,
  })
}

//方案列表分页
export const getPlanPage = data => {
  return axios({
    method: "get",
    url: `/plan/page?size=${data.size}&current=${data.current}&isPublic=${data.isPublic}&planName=${data.planName}`,
  })
}

//方案列表
export const getPlanList = data => {
  return axios({
    method: "get",
    url: `/plan/list?planState=1`,
  })
}

//根据方案ID，生成方案下所有网关的配置文件
export const generateConfigFile = data => {
  return axios({
    method: "get",
    url: `/plan/${data}/generateConfigFile`,
  })
}

//方案与网关关系详情
export const getPlanGatewayRel = data => {
  return axios({ 
    method: "get",
    url: `/planServerTypeRel/${data}`,
  })
}

//创建触发器时候网关的下拉选择  方案ID
export const getGatewayArr = data => {
  return axios({
    method: "get",
    url: `gateway/list?planId=${data}`,
  })
}

//创建触发器时候设备的下拉选择 网关ID
export const getOriginalArr = data => {
  return axios({
    method: "get",
    url: `subOriginal/list?gatewayId=${data}`,
  })
}

// //创建触发器时候参数的下拉选择  设备ID
// export const getParamArr = data => {
//   return axios({
//     method: "get",
//     url: `parameter/subList?subOriginalId=${data}`,
//   })
// }

//创建触发器时候参数的下拉选择 用户ID
export const getSolutionArr = () => {
  return axios({
    method: "get",
    url: `alarmSolution/list`,
  })
}

//创建触发器时候参数的下拉选择 
export const getConditionArr = (data) => {
  return axios({
    method: "get",
    url: `alarm/condition/${data}`,
  })
}

//创建触发器
export const addTrigger = data => {
  return axios({
    method: "post",
    url: `alarm`,
    data: data
  })
}

// 删除触发器 
export const delTrigger = data => {
  return axios({
    method: "delete",
    url: `alarm/${data}`,
  })
}

//编辑触发器
export const editTrigger = data => {
  return axios({
    method: "put",
    url: `alarm`,
    data: data
  })
}

// 触发器详情 
export const getTrigger = data => {
  return axios({
    method: "get",
    url: `alarm/${data}`,
  })
}

//触发器列表分页
export const getTriggerPage = data => {
  return axios({
    method: "get",
    url: `/alarm/page?planId=${data.planId}&size=${data.size}&current=${data.current}`,
  })
}

//创建定时器
export const addTask = data => {
  return axios({
    method: "post",
    url: `timer`,
    data: data
  })
}

//删除定时器
export const delTask = data => {
  return axios({
    method: "delete",
    url: `timer/${data}`,
  })
}

//编辑定时器
export const editTask = data => {
  return axios({
    method: "put",
    url: `timer`,
    data: data
  })
}

//定时任务分页
export const getTaskPage = data => {
  return axios({
    method: "get",
    url: `timer/page?planId=${data.planId}&size=${data.size}&current=${data.current}`,
  })
}

//定时任务详情
export const getTask = data => {
  return axios({
    method: "get",
    url: `timer/${data}`,
  })
}

//方案详情
export const planDetail = data => {
  return axios({
    method: "get",
    url: `plan/${data}`,
  })
}

//根据方案Id查网关
export const planGetgateway = data => {
  return axios({
    method: "get",
    url: `gateway/list?planId=${data}`,
  })
}

//根据方案Id查网关
export const getdtuSns = data => {
  return axios({
    method: "get",
    url: `remoteUpdate/${data}/dtuSns`,
  })
}


//批量远程更新
export const updateSn = data => {
  return axios({
    method: "post",
    url: `remoteUpdate`,
    data: data
  })
}

//获取结果
export const getResult = data => {
  return axios({
    method: "get",
    url: `remoteUpdate/${data}/state`,
  })
}
//获取方案图片列表lanImg
export const getPlanImg = () => {
  return axios({
    method: "get",
    url: `planImg/list`,
  })
}

//获取排序列表
export const setTransfer = data => {
  return axios({
    method: 'get',
    url:`/alarm/planAlarmList/${data}`
  })
}
//更新排序列表
export const updateSort = data => {
  return axios({
    method: 'put',
    url: '/alarm/updateSort',
    data
  })
}