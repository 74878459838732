import axios from '../axios'

//项目组列表分页
export const getProjectGroupPage = data => {
  return axios({
    method: "get",
    url: `projectGroup/page?size=${data.size}&current=${data.current}&projectGroupName=${data.projectGroupName}`,
  })
}
//项目组列表
export const getProjectGroupList = () => {
  return axios({
    method: "get",
    url: `/projectGroup/list?projectGroupName=`,
  })
}

//新增项目组
export const addProjectGroup = data => {
  return axios({
    method: "post",
    url: `projectGroup`,
    data: data
  })
}
//编辑项目组
export const editProjectGroup = data => {
  return axios({
    method: "put",
    url: `projectGroup`,
    data: data
  })
}
//删除项目组
export const delProjectGroup = data => {
  return axios({
    method: "delete",
    url: `projectGroup/${data}`,
  })
}
//项目组详情
export const getProjectGroup = data => {
  return axios({
    method: "get",
    url: `projectGroup/${data}`
  })
}
//联系人不分页
export const getContact = () => {
  return axios({
    method: "get",
    url: `contacts/list`
  })
}
//实例网关列表
export const getSubGatewayList = data => {
  return axios({
    method: "get",
    url: `/planServerTypeRel/list?planId=${data}`,
  })
}

//用户列表不分页
export const userNoPage = () => {
  return axios({
    method: "get",
    url: `user/list`,
  })
}
//通过用户查项目组  分页
export const productPageByUser = data => {
  return axios({
    method: "get",
    url: `projectGroup/page?size=${data.size}&current=${data.current}&projectGroupName=${data.projectGroupName}&createId=${data.createId}`,
  })
}

//获取项目列表不分页
export const getProjectNoPage = data => {
  return axios({
    method: "get",
    url: `project/list?projectGroupId=${data}&projectName`,
  })
}
//获取项目组列表不分页
export const getProjectGroupNoPage = data => {
  return axios({
    method: "get",
    url: `projectGroup/list?createId=${data}&projectGroupName`,
  })
}

//分配项目
export const distributeSubmit = data => {
  return axios({
    method: "put",
    url: `projectGroup/assignProject`,
    data:data
  })
}
//分配用户
export const userSubmit = data => {
  return axios({
    method: "put",
    url: `projectGroup/assignUser`,
    data:data
  })
}

//下级默认无分页 
export const getChildProjectGroupTree = (data) => {
  return axios({
    method: "get",
    url: `/projectGroup/${data}/tree`,
  })
}
//下级用户无分页 
export const getChildUserNoPage = () => {
  return axios({
    method: "get",
    url: `/user/list`,
  })
}

//第三方项目详情
export const getProject = data => {
  return axios({
    method: "get",
    url: `project/${data}`
  })
}

//开启加速
export const openV = data => {
  return axios({
    method: "get",
    url: `project/sendFast/${data}`
  })
}

//查看加速状态
export const checkV = data => {
  return axios({
    method: "get",
    url: `project/state/${data}`
  })
}

//editDetail
export const editDetail = data => {
  return axios({
    method: "get",
    url: `/project/info/${data}`
  })
}
//查询剩余流量
export const getMobile = data => {
  return axios({
    method: "get",
    url: `mobileKey/getMobileInfo?iccid=${data.ccid}&dtuId=${data.dtuId}`
  })
}
//根据字符和SN查询
export const searchList = (data) => {
  return axios({
    method: "get",
    url: `project/userPage/?projectName=${data.projectName}&projectState=${data.projectState}&current=${data.current}&size=${data.size}`,
  });
};
//根据userID查询子账户
export const searchUserChild = data => {
  return axios({
    method:'get',
    url: `/user/subList/${data}`
  })
}