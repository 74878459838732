import axios from '../axios'

//系统属性新增
export const addAttribute = data => {
  return axios({
    method: "post",
    url: `attribute`,
    data:data,
  })
}

//删除系统属性
export const delAttribute = data => {
  return axios({
    method: "delete",
    url: `attribute/${data}`,
 
  })
}

//更新系统属性 
export const putAttribute = () => {
  return axios({
    method: "put",
    url: `attribute`,
    data:data
  })
}

//查看系统属性详情 
export const getAttribute = data => {
  return axios({
    method: "get",
    url: `attribute/${data}`,
  })
}

//查询系统属性分页
export const getAttributePage = data => {
  return axios({
    method: "get",
    url: `attribute/page?size=${data.size}&current=${data.current}&attributeName=${data.attributeName}`,
   
  })
}

//查询系统属性列表
export const getAttributeList = data => {
  return axios({
    method: "get",
    url: `attribute/list?attributeName=${data}`,
   
  })
}

