import { baseUrl } from '../util/global.js'

export default {
  method: "get",
  baseUrl: baseUrl,
  headers: {
      "Content-Type": 'application/json;charset=UTF-8'
  },
  withCredentials: true,
  crossDomain: true
}
