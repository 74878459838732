import axios from '../axios'

//数据条件列表分页
export const getListData = data => {
  return axios({
    method: "get",
    url: `dataCondition/page?size=${data.size}&current=${data.current}&dataConditionName=${data.dataConditionName}`,
  })
}

//数据条件列表
export const getDataConditionList = (data = '') => {
  return axios({
    method: "get",
    url: `dataCondition/list?dataConditionName=${data}`,
  })
}

//数据条件详情
export const getDataCondition = data => {
  return axios({
    method: "get",
    url: `dataCondition/${data}`,
  })
}

//数据条件新增
export const addDataCondition = data => {
  return axios({
    method: "post",
    url: `dataCondition`,
    data:data
  })
}

//数据条件更新
export const editDataCondition = data => {
  return axios({
    method: "put",
    url: `dataCondition`,
    data:data
  })
}

//根据数据条件ID，删除数据条件
export const delDataCondition = data => {
    return axios({
        method: "delete",
        url: `dataCondition/${data}`
    })
}