import axios from '../axios'

//创建服务器
export const createMqtt = data => {
    return axios({
        method: "post",
        url: `mqtt`,
        data: data,

    })
}

//编辑服务器
export const editMqtt = data => {
    return axios({
        method: "put",
        url: `mqtt`,
        data: data,
    })
}

//删除服务器
export const delMqtt = data => {
    return axios({
        method: "delete",
        url: `mqtt/${data}`,
    })
}

// 查询服务器详情
export const getMqtt = data => {
    return axios({
        method: "get",
        url: `mqtt/${data}`,
    })
}

//查看服务器列表（分页）
export const getMqttPage = data => {
    return axios({
        method: "get",
        url: `mqtt/page?current=${data.current}&size=${data.size}&serverName=${data.serverName}&serverIp=${data.serverIp}`,
    })
}


// 查询服务器列表
export const getMqttList = () => {
    return axios({
        method: "get",
        url: `mqtt/list?serverName=`,
        // url: `mqtt/list?serverName=${data.serverName}`,
    })
}

