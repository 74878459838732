import axios from '../axios'

//行业列表分页
export const getListData = data => {
  return axios({
    method: "get",
    url: `industry/page?size=${data.size}&current=${data.current}&industryName=${data.industryName}`,
  })
}

//行业详情
export const industryDetail = data => {
  return axios({
    method: "get",
    url: `industry/${data}`,
  })
}

//新增行业
export const addIndustry = data => {
  return axios({
    method: "post",
    url: `industry`,
    data:data
  })
}

//编辑行业
export const editIndustry = data => {
  return axios({
    method: "put",
    url: `industry`,
    data:data
  })
}

//删除行业
export const delIndustry = data => {
    return axios({
        method: "delete",
        url: `industry/${data}`
    })
}