import axios from '../axios'

//协议列表分页
export const getListData = data => {
  return axios({
    method: "get",
    url: `communicationProtocol/page?size=${data.size}&current=${data.current}&protocolName=${data.protocolName}`,
  })
}

//协议列表不分页
export const getProtocolList = (data = '') => {
  return axios({
    method: "get",
    url: `communicationProtocol/list?protocolName=${data}`,
  })
}

//协议详情
export const communicationProtocolDetail = data => {
  return axios({
    method: "get",
    url: `communicationProtocol/${data}`,
  })
}

//新增协议
export const addcommunicationProtocol = data => {
  return axios({
    method: "post",
    url: `communicationProtocol`,
    data:data
  })
}

//编辑协议
export const editcommunicationProtocol = data => {
  return axios({
    method: "put",
    url: `communicationProtocol`,
    data:data
  })
}

//删除协议
export const delcommunicationProtocol = data => {
    return axios({
        method: "delete",
        url: `communicationProtocol/${data}`
    })
}