import axios from '../axios'

//历史数据
export const getHistoryData = data => {
  return axios({
    method: "post",
    url: `project/historyData`,
    data :data,
  })
}
//行业列表分页
export const getAlarmInfo = data => {
  return axios({
    method: "get",
    url: `/alarmInfo/aiDayCount/${data}`,
    
  })
}
//当前账户下摄像头
export const getProList = data => {
  return axios({
    method: "get",
    url: `/ysDevice/proList/${data.projectId}/${data.current}/${data.size}`,
  })
}
// //视频监控图片
// export const getUserList = data => {
//   return axios({
//     method: "get",
//     url: `/ysDevice/userList/${data.current}/${data.size}`,
//   })
// }
