import axios from '../axios'

//获取项目状态统计
export const getCount = () => {
  return axios({
    method: "get",
    url: `project/count`,
  })
}

//获取报警统计数量
export const getAlarmInfoCount = () => {
  return axios({
    method: "get",
    url: `alarmInfo/count`,
  })
}

//获取不分页项目组列表
export const getProjectGroup = () => {
  return axios({
    method: "get",
    url: `projectGroup/userList`,
  })
}

//查询方案列表
export const getCaseList = () => {
  return axios({
    method: "get",
    url: `plan/list?isPublic=1`,
  })
}

//查询告警分页列表
export const getWarningPage = data => {
  return axios({
    method: "get",
    url: `alarmInfo/page?alarmType=${data.alarmType}&current=${data.current}&size=${data.size}`,
  })
}

//查询方案列表
export const getWarningCount = data => {
  return axios({
    method: "get",
    url: `alarmInfo/projectCount?endDate=${data.endDate}&projectGroupId=${data.projectGroupId}&startDate=${data.startDate}&planId=${data.planId}`,
  })
}

//查询坐标集合
export const getPoint = () => {
  return axios({
    method: "get",
    url: `projectGroup/userList`,
  })
}