import axios from '../axios'

//项目组列表不分页
export const getProjects = () => {
  return axios({
    method: "get",
    url: "projectGroup/userList"
  })
}

//项目列表部不分页
export const getDevices = data => {
  return axios({
    method: "get",
    url: "project/list?projectGroupId=" + data
  })
}

//触发器列表不分页
export const getRules = data => {
  return axios({
    method: "get",
    url: "alarm/project/" + data
  })
}

//获取萤石的摄像头绑定列表分页
export const getYingshiList = data => {
  return axios({
    method: "get",
    url: "ysDevice/" + data.currentPage + "/" + data.pageSize,
    
  })
}

//提交绑定信息
export const submitInfo = data => {
  return axios({
    method: "post",
    url: "ysDevice",
    data: data
  })
}

//编辑绑定信息
export const editInfo = data => {
  return axios({
    method: "put",
    url: "ysDevice",
    data: data
  })
}

//删除绑定信息
export const delInfo = data => {
  return axios({
    method: "delete",
    url: "ysDevice/" + data,
  })
}


//operaCamera 获取accessToken 
export const getToken = (ysId) => {
  return axios({
    method: "get",
    url: "ysSystem/getToken/" + ysId,
  })
}

//编辑萤石获取详情 
export const getInfoForEdit = data => {
  return axios({
    method: "get",
    url: "ysDevice/" + data,
  })
}

// //萤石：设备抓拍图片 
// export const cutPic = data => {
//   return axios({
//     method: "post",
//     url: "https://open.ys7.com/api/lapp/device/capture",
//     data:data
//   })
// }

//萤石：设备抓拍图片 
export const cutPic = data => {
  return axios({
    method: "get",
    url: "ysDevice/capture/" + data,
  })
}
//
export const addYsSystem = data => {
  return axios({
    method: "post",
    url: "ysSystem",
    data:data
  })
}
export const getYsSystem = () => {
  return axios({
    method: "get",
    url: "ysSystem",
  })
}
export const putYsSystem = data => {
  return axios({
    method: "put",
    url: "ysSystem",
    data:data
  })
}
export const getCamera = data => {
  return axios({
    method: "get",
    url: `ysDevice/list/${data}`,
  })
}