import axios from '../axios'
import $store from '../../store/store'

export const exit = () => {
  return axios({
    method: "get",
    url: `logout`,
  })
}

export const open = () => {
  return axios({
    method: "post",
    url: `socket/pushAlarm`,
   
  })
}

export const close = () => {
  return axios({
    method: "delete",
    url: `socket/closeAlarm?tokenId=${$store.state.tokenId}`,
  })
}
export const closeWs = () => {
  return axios({
    method: "delete",
    url: `socket/?tokenId=${$store.state.tokenId}`,
  })
}

