// 页面回显的项目数据
//
//  依赖
//      无
//
//  历史
//      创建    吴迪    2021/06/30
//=============================================================================
//-----------------------------------------------------------------------------
//
var gEchoData = {
    status: {
        historyData: 0,
        devidceData: 0,
        wsData: 0,
        warningData: 0
    },
    warningForm: {
        current: 1,
        size: 5,
        projectId: null,
        totalPage:0
    },
    warningData: [],
    historyObj: {},
    vCommData: {},
    //参数的key
    paramskey: {},
    //实时数据
    wsData: {},
    //设备详情，
    devidceData: {},
    //组态选择图片列表
    imageList: [],
    //历史数据曲线
    historyData: [],
    testTableData: [{
        startTime:"--",
        endDate:"--",
        alarmDuration:"--",
        alarmInfo:"--",
        alarmState:"--",
    },{
        startTime:"--",
        endDate:"--",
        alarmDuration:"--",
        alarmInfo:"--",
        alarmState:"--",
    },{
        startTime:"--",
        endDate:"--",
        alarmDuration:"--",
        alarmInfo:"--",
        alarmState:"--",
    },{
        startTime:"--",
        endDate:"--",
        alarmDuration:"--",
        alarmInfo:"--",
        alarmState:"--",
    },{
        startTime:"--",
        endDate:"--",
        alarmDuration:"--",
        alarmInfo:"--",
        alarmState:"--",
    }]
};

// 外部接口暴露
export default gEchoData;