import axios from '../axios'

//项目临时状态新增
export const addProjectTempState = data => {
  return axios({
    method: 'post',
    url: '/projectTempState',
    data
  })
}

// 项目临时状态分页
export const getProjectTempState = data => {
  return axios({
    method: 'get',
    url:`/projectTempState/page?size=${data.size}&current=${data.current}&projectName=${data.projectName}&dtuSn=${data.dtuSn}&createId=${data.createId}`
  })
}

//项目状态更新
export const updateState = data => {
  return axios({
    method: 'put',
    url: '/projectTempState',
    data
  })
}

//删除状态
export const deleteState = data => {
  return axios({
    method: 'delete',
    url: '/projectTempState',
    data
 })
}

// 获取已选择的项目
export const getStateList = data => {
  return axios({
    method: 'get',
    url:`/projectTempState/list?createId=${data.createId}&projectGroupId=${data.projectGroupId}`,
  })
}