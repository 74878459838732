import axios from '../axios'

//报警联系人列表分页
export const contactsList = data => {
  return axios({
    method: "get",
    url: `/contacts/page?size=${data.size}&current=${data.current}&isPublic=${data.isPublic}`,
  })
}

//报警联系人创建
export const addContacts = data => {
  return axios({
    method: "post",
    url: `/contacts`,
    data:data
  })
}

//报警联系人编辑
export const editContacts = data => {
  return axios({
    method: "put",
    url: `/contacts`,
    data:data
  })
}

//报警联系人删除
export const delContacts = data => {
  return axios({
    method: "delete",
    url: `/contacts/${data}`,
  })
}

//报警联系人
export const detailContacts = data => {
  return axios({
    method: "get",
    url: `/contacts/${data}`,
  })
}

//报警联系人
export const processingMethod = () => {
  return axios({
    method: "get",
    url: `/processingMethod/list`,
  })
}
//获取短信，语音数量
export const getSmsNum = () => {
  return axios({
    method: 'get',
    url:'/alarmSmsUser/findSmsNum'
  })
}
//获取套餐列表
export const getSmsPack = () => {
  return axios({
    method: 'get',
    url:'/alarmSmsUser/findSmsPack'
  })
}
//短信语音充值接口
export const NativePay = data => {
  return axios({
    method: 'post',
    url:'/wechatPay/nativePay',
    data
  })
}
//支付状态
export const payStatus = data => {
  return axios({
    method: 'get',
    url:`/alarmSmsUser/findSmsLog/${data}`
  })
}
//获取充值列表
export const getRecords = data => {
  return axios({
    method: 'get', 
    url:`/alarmSmsUser/payLogPage?size=${data.size}&current=${data.current}`
  })
}
//删除充值订单
export const deleteRecords = data => {
  return axios({
    method: 'delete',
    url:`/alarmSmsUser//payLog/${data}`
  })
}
// 开通短信语音状态
export const updateState = data => {
  return axios({
    method: 'put',
    url: '/alarmSmsUser/upSmsState',
    data
  })
}

