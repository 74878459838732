import axios from '../axios'

//获取菜单列表
export const getMenu = () => {
  return axios({
    method: "get",
    url: `menu/list`,
  })
}

//获取单个取菜单详情
export const getSingleMenuDetail = data => {
  return axios({
    method: "get",
    url: `menu/${data}`,
  })
}

//创建菜单
export const createMenu = data => {
  return axios({
    method: "post",
    url: `menu`,
    data: data
  })
}

//编辑菜单
export const editMenu = data => {
  return axios({
    method: "put",
    url: `menu`,
    data:data
  })
}

//删除菜单
export const deleteMenu = data => {
  return axios({
    method: 'delete',
    url: `menu/${data}`
  })
}
//开通功能
export const postOpen = data => {
  return axios({
    method: 'put',
    url: `menu/open`,
    data:data
  })
}