import axios from '../axios'

//数据条件列表分页
export const getListBase = data => {
  return axios({
    method: "get",
    url: `baseCondition/page?size=${data.size}&current=${data.current}&baseConditionName=${data.baseConditionName}`,
  })
}

//数据条件列表
export const getBaseConditionList = (data = '') => {
  return axios({
    method: "get",
    url: `baseCondition/list?baseConditionName=${data}`,
  })
}

//数据条件详情
export const getBaseCondition = data => {
  return axios({
    method: "get",
    url: `baseCondition/${data}`,
  })
}

//数据条件新增
export const addBaseCondition = data => {
  return axios({
    method: "post",
    url: `baseCondition`,
    data:data
  })
}

//数据条件更新
export const editBaseCondition = data => {
  return axios({
    method: "put",
    url: `baseCondition`,
    data:data
  })
}

//根据数据条件ID，删除数据条件
export const delBaseCondition = data => {
    return axios({
        method: "delete",
        url: `baseCondition/${data}`
    })
}

//根据数据条件ID，删除数据条件
export const baseConditionList = () => {
    return axios({
        method: "get",
        url: `baseCondition/list`
    })
}