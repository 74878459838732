let guardList = new Array();
// let permissionList = new Array();
let defaultActive = 0;
let funcGuard = (guard) => {
    guard.forEach(element => {
        guardList.push(element.menuPath);
        if (element.children) {
            funcGuard(element.children)
        }
    });
}
// let funcPermArr = (permiArr) => {
//     permiArr.forEach(element => {
//         if (element.permission.length > 0) {
//             permissionList.push(element.permission);
//         }
//         if (element.children) {
//             funcPermArr(element.children)
//         }
//     });
// }
let funcActive = (menu) => {
    if (menu[0].children && menu[0].children.length > 0) {
        funcActive(menu[0].children)
    } else {
        defaultActive = menu[0].menuPath
    }
}
// 全局守卫数据
export const getGuard = (guard = []) => {
    funcGuard(guard);
    return guardList
}
// 按钮权限数据
// export const getPermissions = (permiArr = []) => {
//     funcPermArr(permiArr);
//     return permissionList
// }
// 路由菜单数据
export const addDynamicMenuRoutes = (menuList = [], routes = []) => {
    for (let i = 0; i < menuList.length; i++) {
        if (menuList[i].menuPath && /\S/.test(menuList[i].menuPath) && menuList[i].menuPath != '#') {
            // 创建路由配置
            let route = Object.assign({}, menuList[i], { children: [] });
            try {
                route['component'] = () => {
                    import(`../view/${menuList[i].menuTag}/index.vue`);
                }
            } catch (error) {
                console.log(error)
            }
            if (menuList[i].children && menuList[i].children.length >= 1) {
                let temp = [];
                temp = temp.concat(menuList[i].children);
                if (temp.length >= 1) {
                    let menuItem = addDynamicMenuRoutes(temp);
                    route.children = menuItem;
                }
            }
            routes.push(route);
        }
    }
    return routes;
}
//获取默认的菜单
export const getDefaultMenuActive = (menuList = []) => {
    funcActive(menuList);
    return defaultActive;
}
export default {
    getGuard,
    // getPermissions,
    addDynamicMenuRoutes,
    getDefaultMenuActive
}