import axios from '../axios'

//下级账号列表
export const accountList = data => {
  return axios({
    method: "get",
    url: `user/page?current=${data.current}&size=${data.size}&username=${data.username}&mobile=${data.mobile}&fullName=${data.fullName}`,
  })
}

//创建下级账号
export const createAccount = data => {
  return axios({
    method: "post",
    url: `user`,
    data: data
  })
}

//角色列表不分页 
export const getRoleNoPage = () => {
  return axios({
    method: "get",
    url: `role/list`,
  })
}

//查看用户详情 
export const getAccount = data => {
  return axios({
    method: "get",
    url: `user/${data}`,
  })
}

//编辑用户 
export const editAccount = data => {
  return axios({
    method: "put",
    url: `user`,
    data: data
  })
}

//修改密码
export const updatePwd = data => {
  return axios({
    method: "put",
    url: `user/pwd`,
    data: data
  })
}

//删除用户 
export const delAccount = data => {
  return axios({
    method: "delete",
    url: `user/${data}`,
  })
}