import axios from '../axios'

//数据应用分页
export const getListData = data => {
  return axios({
    method: "get",
    url: `dataApplication/page?size=${data.size}&current=${data.current}&dataApplicationName=${data.dataApplicationName}&planId=${data.planId}`,
  })
}

//数据应用列表
export const getProtocolList = (data) => {
  return axios({
    method: "get",
    url: `dataApplication/list?subOriginalId=${data.subOriginalId}&planId=${data.planId}    `,
  })
}

//数据应用详情
export const getdataApplication = data => {
  return axios({
    method: "get",
    url: `dataApplication/${data}`,
  })
}

//数据应用新增
export const adddataApplication = data => {
  return axios({
    method: "post",
    url: `dataApplication`,
    data:data
  })
}

//数据应用更新
export const editdataApplication = data => {
  return axios({
    method: "put",
    url: `dataApplication`,
    data:data
  })
}

//数据应用ID，删除数据应用
export const deldataApplication= data => {
    return axios({
        method: "delete",
        url: `dataApplication/${data}`
    })
}

//数据应用列表
export const getplanIdProtocolList = (data) => {
  return axios({
    method: "get",
    url: `dataApplication/daList?planId=${data}    `,
  })
}
//数据应用历史查询
export const getApplicationHistory = (data) => {
  return axios({
    method: "post",
    url: `dataApplication/history`,
    data:data,
  })
}
//获取尖峰平谷价钱
export const getDataSpfvMoney = (data) => {
  return axios({
    method: "get",
    url: `dataSpfvMoney/${data}`,
  })
}
//添加尖峰平谷价钱
export const postDataSpfvMoney = (data) => {
  return axios({
    method: "post",
    url: `dataSpfvMoney`,
    data:data,
  })
}
//修改尖峰平谷价钱
export const putDataSpfvMoney = (data) => {
  return axios({
    method: "put",
    url: `dataSpfvMoney`,
    data:data,
  })
}
//删除尖峰平谷价钱
export const delDataSpfvMoney = (data) => {
  return axios({
    method: "delete",
    url: `dataSpfvMoney/${data}`,
    
  })
}

//单项目尖峰平谷查询
export const getApplicationProHistory = (data) => {
  return axios({
    method: "post",
    url: `dataApplication/proHistory`,
    data:data,
  })
}